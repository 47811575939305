import { Checkbox, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Flex, Image, Input, Select, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, WrapItem, Wrap, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, List, ListItem, RadioGroup, Stack, Radio, CheckboxGroup, Link, Textarea, InputRightElement, InputGroup, useToast, Spinner } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import video from "../../images/videos.png"
import apps from "../../images/apps.png"
import { transform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api'
import { CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import CryptoJS from 'crypto-js';
import "./My.css"
import Cookies from 'js-cookie';
import { format, addDays } from 'date-fns';
const libraries = ['places'];

// function Checkbox({ label, isSelected, onCheckboxChange }) {
//     return (
//         <div className="checkbox">

//             <input
//                 type="checkbox"
//                 value={label}
//                 checked={isSelected}
//                 onChange={onCheckboxChange}
//             />
//             <label>   {label}
//             </label>
//         </div>
//     );
// }
function UserHome() {



    const [isOpen, setIsOpen] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLocation, setSelectedLocation] = useState("Nepal");
    const [selectedOptions, setSelectedOptions] = useState(["Nepali"]);
    const [error, setError] = useState('');
    const today = new Date();
    const startDate = format(today, 'yyyy-MM-dd');

    // Calculate the date 15 days from today
    const futureDate = addDays(today, 15);
    const endDate = format(futureDate, 'yyyy-MM-dd');
    const emailCookie = Cookies.get('email');
    const emailuser = emailCookie ? JSON.parse(emailCookie) : null;
    const user = JSON.parse(sessionStorage.getItem("email")) || ""

    let [loadY, setLoadY] = useState(false)
    console.log(user)

    const [formData, setFormData] = useState({
        user_id: user.email,
        ad_platform: "Youtube",
        campaign_name: 'ad1',
        campaign_subtype: '',
        budget: '',
        start_date: startDate,
        end_date: endDate,
        location: ["Nepal"],
        language: ['Nepali'],
        ad_schedule: [],
        gender: ['Male', 'Female', 'Unknown'], // Default checked values for gender
        age: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'], // Default checked values for age
        parental_status: ['Not-a-parent', 'Parent', 'Unknown'], // Default checked values for parental status
        Householdincome: ['Top10', '11-20', '21-30', '31-40', '41-50', 'Lower50', 'Unknown'], // Default checked values for household income
        keywords: [],
        youtube_video: [],
    });
    const isBudgetComplete = formData.budget !== '';
    const isEndDateComplete = formData.end_date !== '';

    const isVideoAdComplete = formData.youtube_video.length > 0;

    let toast = useToast();

    const handleBudgetChange = (e) => {
        const value = e.target.value;
        if (value === '' || parseInt(value) >= 500) {
            setError('');
        } else {
            setError('Minimum Budget is of NPR 500');
        }
        setFormData({ ...formData, budget: value });
    };
    const handleFormDataChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            campaign_subtype: value
        }));
    };

    const handleCheckboxChange = (category, value) => {
        setFormData(prevState => {
            // Check if the value is already in the array
            const isChecked = prevState[category].includes(value);
            let updatedCategory;

            if (isChecked) {
                // If the value is already selected, remove it
                updatedCategory = prevState[category].filter(item => item !== value);
            } else {
                // If the value is not selected, add it
                updatedCategory = [...prevState[category], value];
            }

            // Return updated state with the modified category
            return {
                ...prevState,
                [category]: updatedCategory
            };
        });
    };




    const handleKeywords = (value) => {
        setFormData(prevState => ({
            ...prevState,
            keywords: value.split(',').map(keyword => keyword.trim())
        }));
    };




    const handleAdScheduleChange = (index, field, value) => {
        setFormData(prevState => {
            const updatedAdSchedule = [...prevState.ad_schedule];
            let updatedEntry = updatedAdSchedule[index];

            // If the entry doesn't exist at the specified index, create a new object
            if (!updatedEntry) {
                updatedEntry = {};
                updatedAdSchedule[index] = updatedEntry;
            }

            // Update the field of the object at the specified index
            updatedEntry[field] = value;

            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };




    const options = [
        "All Lanuages",
        "Nepali",
        "English",
        "Hindi",
        "Afar",
        "Afrikaans",
        "Akan",
        "Albanian",
        "Amharic",
        "Arabic",
        "Aramaic",
        "Armenian",
        "Ashanti",
        "Aymará",
        "Azerbaijani",
        "Bafut",
        "Bahasa",
        "Bambara",
        "Basque",
        "Bassa",
        "Belarussian",
        "Bemba",
        "Bengali",
        "Bislama",
        "Blackfoot",
        "Bosnian",
        "Breton",
        "Bulgarian",
        "Burmese",
        "Cajun",
        "Cambodian",
        "Cantonese",
        "Catalan",
        "Cebuano",
        "Chamoro",
        "Chichewa",
        "Chinese",
        "Chinook",
        "Creole",
        "Croatian",
        "Crow",
        "Czech",
        "Danish",
        "Dari",
        "Dhivehi",
        "Dutch",
        "Dzongkha",
        "Edo",
        "Papiamento",
        "Polish",
        "Polynesian",
        "Portuguese",
        "Provencal",
        "Punjabi",
        "Pushtu",
        "Quechua",
        "Romanian",
        "Russian",
        "Samoan",
        "Sanskrit",
        "Scots",
        "Serbian",
        "Sesotho",
        "Sign Language – American",
        "Sindhi",
        "Sinhala",
        "Sinhalese",
        "Sioux",
        "Estonian",
        "Ewe",
        "Faroese",
        "Farsi",
        "Fijian",
        "Fijian Hindi",
        "Filipino",
        "Finnish",
        "Flemish",
        "French",
        "Frisian",
        "Fulani",
        "Fuuta Jalon",
        "Ga",
        "Gaelic",
        "Galician",
        "Georgian",
        "German",
        "Gikuyu",
        "Greek",
        "Greenlandic",
        "Guaraní",
        "Gujarati",
        "Hausa",
        "Hawaiian",
        "Hebrew",
        "Hmong",
        "Hungarian",
        "Ibo",
        "Icelandic",
        "Ilocano",
        "Ilonggo",
        "Indonesian",
        "Italian",
        "Japanese",
        "Jola",
        "Kannada",
        "Karen",
        "Kazakh",
        "Khalkha Mongol",
        "Khmer",
        "Kinyarwanda",
        "Slovak",
        "Somali",
        "Soninke",
        "Spanish",
        "Sranan",
        "Swahili",
        "Swati",
        "Swedish",
        "Tagalog",
        "Taiwanese",
        "Tajik",
        "Tamil",
        "Telugu",
        "Tetum",
        "Thai",
        "Tibetan",
        "Tigrigna",
        "Tokelauan",
        "Tongan",
        "Turkish",
        "Kirghiz",
        "Kirundi",
        "Kissi",
        "Kiswahili",
        "Koniagui",
        "Kono",
        "Korean",
        "Kurdish",
        "Kwanyama",
        "Kyrgyz",
        "Laotian",
        "Latin",
        "Latvian",
        "Liberian",
        "Lingala",
        "Lithuanian",
        "Luxemburgian",
        "Macedonian",
        "Malagasy",
        "Malay",
        "Malayalam",
        "Malinke",
        "Maltese",
        "Mandarin",
        "Mandingo",
        "Mandinka",
        "Maori",
        "Marathi",
        "Marshallese",
        "Mirandese",
        "Moldovan",
        "Mongolian",
        "Nauruan",
        "Navajo",
        "Ndebele",
        "Niuean",
        "Norwegian",
        "Nzema",
        "Oriya",
        "Oromo",
        "Ossetian",
        "Otetela",
        "Palauan",
        "Turkman",
        "Tuvaluan",
        "Twi",
        "Tzotzil",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Valencian",
        "Vietnamese",
        "Vlaams",
        "Wallisian",
        "Welsh",
        "Wolof",
        "Xhosa",
        "Yanomami",
        "Yiddish",
        "Yoruba",
        "Zarma",
        "Zulu"
    ];
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    const [activeSocialMedia, setActiveSocialMedia] = useState('youtube');
    const { isOpen: openModal1, onOpen: onOpen1, onClose: onModalClose1 } = useDisclosure()
    const originRef = useRef()
    let navigate = useNavigate()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()
    const [searchInput, setSearchInput] = useState("");


    const handleIconClick = (socialMedia) => {
        setActiveSocialMedia(socialMedia);
    };

    const [amount, setAmount] = useState(100);
    const [taxAmount, setTaxAmount] = useState(0);
    const [transactionUUID, setTransactionUUID] = useState(`tx-${Date.now()}`);
    const [signature, setSignature] = useState('');

    const MERCHANT_SECRET = '8gBm/:&EnhH.1/q(';
    const MERCHANT_CODE = 'EPAYTEST';
    const SUCCESS_URL = 'http://localhost:3000/transaction/success';
    const FAILURE_URL = 'http://localhost:3000/transaction/failure';



    let handleSubmit = async () => {
        // e.preventDefault();
        setLoadY(true)
        if (formData.budget === "" ||
            formData.campaign_subtype === "" || formData.start_date === "" || formData.end_date === ""

        ) {
            toast({
                title: 'Error',
                description: "Please fill all the details",
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: "top-center"
            })
            setLoadY(false)
        } else {
            if (formData.budget >= 500) {
                try {
                    let response = await fetch(`https://releasesnepal.net/youtube`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(formData)
                    })
                    let res = await response.json();
                    if (response.ok) {
                        await PaymentSend(res.data.AcNumber);
                        setLoadY(false)
                    }
                } catch (error) {
                    console.log(error)
                    setLoadY(false)
                }
            } else {
                toast({

                    description: "Minimum budget is of NPR 500",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    position: "top-center"
                });
                setLoadY(false)
            }
        }
    }

    const PaymentSend = async (referenceId) => {
        // Set loading state (optional)
        // setLoading(true);

        const paymentData = {
            amount: parseFloat(formData.budget),
            tax_amount: parseFloat(taxAmount),
            emailuser,
            referenceId
        };

        try {
            const response = await fetch('https://releasesnepal.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            const { formHtml } = await response.json();

            // Replace the current page's content with the form HTML and submit it
            document.body.innerHTML = formHtml;

            // Attach an event listener to call `onPaymentSuccess` after the form is submitted
            const paymentForm = document.forms['paymentForm'];

            paymentForm.addEventListener('submit', async (event) => {
                event.preventDefault(); // Prevent the default form submission
                await handleSubmit();
                paymentForm.submit(); // Submit the form programmatically
                // Call your success function
            });

            paymentForm.submit(); // Trigger the form submission
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Reset loading state (optional)
            // setLoading(false);
        }
    }
        ;



    const handleLocationChange = (e) => {
        if (selectedLocation === "other" && e !== "other") {
            // Clear the selected locations array if switching from "other" to another option
            setSelectedLocations([]);
        }
        setSelectedLocation(e);
        if (e === "other") {
            // If "other" is selected, clear the formData.location array
            setFormData(prevData => ({
                ...prevData,
                location: []
            }));
        } else if (e === "all-countries&territories") {
            // If "all-countries&territories" is selected, store only it
            setFormData(prevData => ({
                ...prevData,
                location: ["all-countries&territories"]
            }));
        } else if (e === "Nepal") {
            // If "Nepal" is selected, store only it
            setFormData(prevData => ({
                ...prevData,
                location: ["Nepal"]
            }));
        }
    };



    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: `AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`,
        libraries,
    })

    const autocompleteRef = useRef();


    const handlePlaceSelect = () => {
        if (selectedLocation === "other") {
            const place = autocompleteRef.current.getPlace();
            if (place && place.formatted_address) {
                const newLocation = {
                    id: Math.random().toString(36).substring(7),
                    address: place.formatted_address
                };
                setSelectedLocations(prevLocations => [...prevLocations, newLocation]);
                setFormData(prevData => ({
                    ...prevData,
                    location: [...prevData.location, newLocation.address]
                }));
                setSearchInput("");
            }
        } else {
            setSelectedLocations([selectedLocation]);
            setFormData(prevData => ({
                ...prevData,
                location: [selectedLocation]
            }));
        }
    };



    const removeLocation = (id) => {
        // Filter out the location with the given id
        const updatedLocations = selectedLocations.filter(location => location.id !== id);
        // Update the selectedLocations state
        setSelectedLocations(updatedLocations);

        // Update the formData with the updated locations
        setFormData({
            ...formData,
            location: updatedLocations, // Update the location field
        });
    };


    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };


    const handleOptionSelect = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };



    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            language: selectedOptions
        }));
    }, [selectedOptions]);

    const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleRemoveOption = (option) => {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
    };

    const handleAddButtonClick = () => {
        const newIndex = boxes.length;
        const newBox = (
            <Box key={newIndex} mt={newIndex === 0 ? 0 : 4}>
                <Flex alignItems="center" gap={4}>
                    <Select onChange={(e) => handleAdScheduleChange(newIndex, 'day', e.target.value)}>
                        <option value="All-days">All Days</option>
                        <option value="Mondays-Fridays">Mondays-Fridays</option>
                        <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                        <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                        <option value="Mondays">Mondays</option>
                        <option value="Tuesdays">Tuesdays</option>
                        <option value="Wednesdays">Wednesdays</option>
                        <option value="Thursdays">Thursdays</option>
                        <option value="Fridays">Fridays</option>
                        <option value="Saturdays">Saturdays</option>
                        <option value="Sundays">Sundays</option>
                    </Select>
                    <Input type="time" onChange={(e) => handleAdScheduleChange(newIndex, 'startTime', e.target.value)} />
                    <Text>to</Text>
                    <Input type="time" onChange={(e) => handleAdScheduleChange(newIndex, 'endTime', e.target.value)} />
                </Flex>
            </Box>
        );
        setBoxes(prevBoxes => [...prevBoxes, newBox]);
    };

    const handleRemoveButtonClick = (indexToRemove) => {
        setBoxes(prevBoxes => prevBoxes.filter((box, index) => index !== indexToRemove));
        setFormData(prevState => {
            const updatedAdSchedule = prevState.ad_schedule.filter((_, index) => index !== indexToRemove);
            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };

    const initialBox = (
        <Box key={0} index={0} mt={0}>
            <Flex alignItems="center" gap={4}>
                <Select
                    onChange={(e) => handleAdScheduleChange(0, 'day', e.target.value)}
                    isRequired
                >
                    <option value="">Select Days</option>
                    <option value="All-days">All Days</option>
                    <option value="Mondays-Fridays">Mondays-Fridays</option>
                    <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                    <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                    <option value="Mondays">Mondays</option>
                    <option value="Tuesdays">Tuesdays</option>
                    <option value="Wednesdays">Wednesdays</option>
                    <option value="Thursdays">Thursdays</option>
                    <option value="Fridays">Fridays</option>
                    <option value="Saturdays">Saturdays</option>
                    <option value="Sundays">Sundays</option>
                </Select>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChange(0, 'startTime', e.target.value)}
                    isRequired
                />
                <Text>to</Text>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChange(0, 'endTime', e.target.value)}
                    isRequired
                />
            </Flex>
        </Box>
    );

    const [boxes, setBoxes] = useState([initialBox]);

    const API_KEY = "AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU";

    let [search, setSearchValue] = useState("")
    let [youtube, setYoutube] = useState([])

    let handleChangeYoutube = (e) => {

        if (selectedVideos === 5) {
            setSearchValue("")
        } else {
            setSearchValue(e.target.value)
        }
    }

    let id;

    const debounce = (func) => {
        if (id) {
            clearTimeout(id);
        }

        id = setTimeout(() => {
            func();
        }, 2000);
    };
    // console.log(search)
    let main = async () => {
        try {
            let response = await fetch(
                `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=5&q=${search}&type=video&key=${API_KEY}`
            );
            let data = await response.json();

            setYoutube(data.items);

        } catch (error) {
            console.log(error);
        }
    }

    const [selectedVideos, setSelectedVideos] = useState([]);

    const handleVideo = (video) => {
        setSelectedVideos(prevState => {
            const updatedVideos = [...prevState, video];
            setFormData(prevFormData => ({
                ...prevFormData,
                youtube_video: updatedVideos
            }));
            setSearchValue("")
            setYoutube([])
            return updatedVideos;
        });
    };

    let handleSelectedChange = (ele) => {
        window.open(`https://www.youtube.com/watch?v=${ele.id.videoId}`, '_blank');
    }






    const [accordionIsOpen1, setAccordionIsOpen1] = useState(false);

    const toggleAccordion1 = () => {
        setAccordionIsOpen1(!accordionIsOpen1);
    };


    const [accordionIsOpen2, setAccordionIsOpen2] = useState(false);

    const toggleAccordion2 = () => {
        setAccordionIsOpen2(!accordionIsOpen2);
    };

    const [accordionIsOpen3, setAccordionIsOpen3] = useState(false);

    const toggleAccordion3 = () => {
        setAccordionIsOpen3(!accordionIsOpen3);
    };

    const [accordionIsOpen4, setAccordionIsOpen4] = useState(false);

    const toggleAccordion4 = () => {
        setAccordionIsOpen4(!accordionIsOpen4);
    };

    const [accordionIsOpen5, setAccordionIsOpen5] = useState(false);

    const toggleAccordion5 = () => {
        setAccordionIsOpen5(!accordionIsOpen5);
    };

    const [accordionIsOpen6, setAccordionIsOpen6] = useState(false);

    const toggleAccordion6 = () => {
        setAccordionIsOpen6(!accordionIsOpen6);
    };

    const [accordionIsOpen7, setAccordionIsOpen7] = useState(false);

    const toggleAccordion7 = () => {
        setAccordionIsOpen7(!accordionIsOpen7);
    };

    const [accordionIsOpen8, setAccordionIsOpen8] = useState(false);

    const toggleAccordion8 = () => {
        setAccordionIsOpen8(!accordionIsOpen8);
    };

    const [accordionIsOpen9, setAccordionIsOpen9] = useState(false);

    const toggleAccordion9 = () => {
        setAccordionIsOpen9(!accordionIsOpen9);
    };





    //for facebook
    const [accordionIsOpenf1, setAccordionIsOpenf1] = useState(false);

    const toggleAccordionf1 = () => {
        setAccordionIsOpenf1(!accordionIsOpenf1);
    };

    const [accordionIsOpenf2, setAccordionIsOpenf2] = useState(false);

    const toggleAccordionf2 = () => {
        setAccordionIsOpenf2(!accordionIsOpenf2);
    };

    const [accordionIsOpenf3, setAccordionIsOpenf3] = useState(false);

    const toggleAccordionf3 = () => {
        setAccordionIsOpenf3(!accordionIsOpenf3);
    };

    const [accordionIsOpenf4, setAccordionIsOpenf4] = useState(false);

    const toggleAccordionf4 = () => {
        setAccordionIsOpenf4(!accordionIsOpenf4);
    };

    const [accordionIsOpenf5, setAccordionIsOpenf5] = useState(false);

    const toggleAccordionf5 = () => {
        setAccordionIsOpenf5(!accordionIsOpenf5);
    };

    const [accordionIsOpenf6, setAccordionIsOpenf6] = useState(false);

    const toggleAccordionf6 = () => {
        setAccordionIsOpenf6(!accordionIsOpenf6);
    };

    const [accordionIsOpenf7, setAccordionIsOpenf7] = useState(false);

    const toggleAccordionf7 = () => {
        setAccordionIsOpenf7(!accordionIsOpenf7);
    };

    const [accordionIsOpenf8, setAccordionIsOpenf8] = useState(false);

    const toggleAccordionf8 = () => {
        setAccordionIsOpenf8(!accordionIsOpenf8);
    };

    const [accordionIsOpenf9, setAccordionIsOpenf9] = useState(false);

    const toggleAccordionf9 = () => {
        setAccordionIsOpenf9(!accordionIsOpenf9);
    };

    let [loadF, setLoadF] = useState(false)

    const [formData1, setFormData1] = useState({
        user_id: user.email,
        ad_platform: "Facebook",
        campaign_name: 'ad1',
        campaign_subtype: '',
        budget: '',
        start_date: startDate,
        end_date: endDate,
        location: ["Nepal"],
        language: ['Nepali'],
        ad_schedule: [],
        gender: ['Male', 'Female', 'Unknown'], // Default checked values for gender
        age: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'], // Default checked values for age
        parental_status: ['Not-a-parent', 'Parent', 'Unknown'], // Default checked values for parental status
        Householdincome: ['Top10', '11-20', '21-30', '31-40', '41-50', 'Lower50', 'Unknown'], // Default checked values for household income
        keywords: [],
        imagesTosend: [],
        urltoSend: [],
        question: [],
        applink: ""
    });

    const [images, setImages] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [questions, setQuestions] = useState(['']);
    const [productImage, setProductImage] = useState([]);
    // const [appLink, setAppLink] = useState('');
    const [error1, setError1] = useState('');

    const handleBudgetChangeF = (e) => {
        const value = e.target.value;
        if (value === '' || parseInt(value) >= 500) {
            setError1('');
        } else {
            setError1('Minimum Budget is of NPR 500');
        }
        setFormData1({ ...formData1, budget: value });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);
        setFormData1(prevFormData => ({
            ...prevFormData,
            imagesTosend: files
        }))
    };

    const handleVideoUrlChange = (e, index) => {
        const newVideoUrls = [...videoUrls];
        newVideoUrls[index] = e.target.value;
        setVideoUrls(newVideoUrls);
        setFormData1(prevFormData => ({
            ...prevFormData,
            urltoSend: newVideoUrls
        }))
    };

    const handleQuestionChange = (e, index) => {
        const newQuestions = [...questions];
        newQuestions[index] = e.target.value;
        setQuestions(newQuestions);
        setFormData1(prevData => ({
            ...prevData,
            question: newQuestions
        }));
    };

    const handleAddQuestion = () => {
        if (questions.length < 10) {
            setQuestions([...questions, '']);
        }
    };

    const handleAddVideoUrl = () => {
        if (videoUrls.length < 3) {
            setVideoUrls([...videoUrls, '']);
        }
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    const handleRemoveVideoUrl = (index) => {
        const newVideoUrls = videoUrls.filter((_, i) => i !== index);
        setVideoUrls(newVideoUrls);
    };

    const renderImageInput = () => (
        <Input
            mt={"20px"}
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            required
        />
    );

    const renderVideoUrlInput = () => (
        <>
            {videoUrls.map((url, index) => (
                <InputGroup key={index} size="md" mt={4}>
                    <Input
                        type="url"
                        placeholder="Enter video URL"
                        value={url}
                        onChange={(e) => handleVideoUrlChange(e, index)}
                        required
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="2.1rem" mr={'5px'} color={"white"} bg={"red"} size="sm" onClick={() => handleRemoveVideoUrl(index)}>
                            Remove
                        </Button>
                    </InputRightElement>
                </InputGroup>
            ))}
            {videoUrls.length < 3 && (
                <Button mt={4} color={"white"} bg={"rgb(24,112,229)"} size={"md"} onClick={handleAddVideoUrl}>Add Video URL</Button>
            )}
        </>
    );

    const renderQuestions = () => (
        <>
            {questions.map((ques, index) => (
                <Box key={index} mt={"10px"}>
                    <Textarea
                        placeholder="Enter question"
                        value={ques}
                        onChange={(e) => handleQuestionChange(e, index)}
                        required
                    />
                    <Button size={"sm"} mt={"10px"} color={"white"} bg={"red"} onClick={() => handleRemoveQuestion(index)}>Remove</Button>
                </Box>
            ))}
            {questions.length < 10 && (
                <Button color={"white"} mt={"20px"} bg={"rgb(24,112,229)"} onClick={handleAddQuestion}>Add Question</Button>
            )}
        </>
    );


    const handleCheckboxChangeF = (category, value) => {
        setFormData1(prevState => {
            // Check if the value is already in the array
            const isChecked = prevState[category].includes(value);
            let updatedCategory;

            if (isChecked) {
                // If the value is already selected, remove it
                updatedCategory = prevState[category].filter(item => item !== value);
            } else {
                // If the value is not selected, add it
                updatedCategory = [...prevState[category], value];
            }

            // Return updated state with the modified category
            return {
                ...prevState,
                [category]: updatedCategory
            };
        });
    };

    const handleKeywordsF = (value) => {
        setFormData1(prevState => ({
            ...prevState,
            keywords: value.split(',').map(keyword => keyword.trim())
        }));
    };

    const handleAdScheduleChangeF = (index, field, value) => {
        setFormData1(prevState => {
            const updatedAdSchedule = [...prevState.ad_schedule];
            let updatedEntry = updatedAdSchedule[index];

            // If the entry doesn't exist at the specified index, create a new object
            if (!updatedEntry) {
                updatedEntry = {};
                updatedAdSchedule[index] = updatedEntry;
            }

            // Update the field of the object at the specified index
            updatedEntry[field] = value;

            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };

    const [selectedLocationsF, setSelectedLocationsF] = useState([]);
    const [searchTermF, setSearchTermF] = useState('');
    const [selectedLocationF, setSelectedLocationF] = useState("Nepal");
    const [selectedOptionsF, setSelectedOptionsF] = useState(["Nepali"]);
    const [searchInputF, setSearchInputF] = useState("");

    const handleFormDataChangef = (value) => {
        setFormData1(prevState => ({
            ...prevState,
            campaign_subtype: value
        }));
    };

    const handleLocationChangeF = (e) => {
        if (selectedLocationF === "other" && e !== "other") {
            // Clear the selected locations array if switching from "other" to another option
            setSelectedLocationsF([]);
        }
        setSelectedLocationF(e);
        if (e === "other") {
            // If "other" is selected, clear the formData.location array
            setFormData1(prevData => ({
                ...prevData,
                location: []
            }));
        } else if (e === "all-countries&territories") {
            // If "all-countries&territories" is selected, store only it
            setFormData1(prevData => ({
                ...prevData,
                location: ["all-countries&territories"]
            }));
        } else if (e === "Nepal") {
            // If "Nepal" is selected, store only it
            setFormData1(prevData => ({
                ...prevData,
                location: ["Nepal"]
            }));
        }
    };

    const handlePlaceSelectF = () => {
        if (selectedLocationF === "other") {
            const place = autocompleteRef.current.getPlace();
            if (place && place.formatted_address) {
                const newLocation = {
                    id: Math.random().toString(36).substring(7),
                    address: place.formatted_address
                };
                setSelectedLocationsF(prevLocations => [...prevLocations, newLocation]);
                setFormData1(prevData => ({
                    ...prevData,
                    location: [...prevData.location, newLocation.address]
                }));
                setSearchInputF("");
            }
        } else {
            setSelectedLocationsF([selectedLocationF]);
            setFormData1(prevData => ({
                ...prevData,
                location: [selectedLocationF]
            }));
        }
    };

    const removeLocationF = (id) => {
        // Filter out the location with the given id
        const updatedLocations = selectedLocationsF.filter(location => location.id !== id);
        // Update the selectedLocations state
        setSelectedLocationsF(updatedLocations);

        // Update the formData with the updated locations
        setFormData1({
            ...formData1,
            location: updatedLocations, // Update the location field
        });
    };

    const handleInputChangeF = (e) => {
        setSearchTermF(e.target.value);
    };

    const handleOptionSelectF = (option) => {
        if (selectedOptionsF.includes(option)) {
            setSelectedOptionsF(selectedOptionsF.filter((item) => item !== option));
        } else {
            setSelectedOptionsF([...selectedOptionsF, option]);
        }
    };

    const filteredOptionsF = options.filter((option) =>
        option.toLowerCase().includes(searchTermF.toLowerCase())
    );

    const handleRemoveOptionF = (option) => {
        setSelectedOptionsF(selectedOptionsF.filter((item) => item !== option));
    };

    const handleAddButtonClickF = () => {
        const newIndex = boxesF.length;
        const newBox = (
            <Box key={newIndex} mt={newIndex === 0 ? 0 : 4}>
                <Flex alignItems="center" gap={4}>
                    <Select onChange={(e) => handleAdScheduleChangeF(newIndex, 'day', e.target.value)}>
                        <option value="All-days">All Days</option>
                        <option value="Mondays-Fridays">Mondays-Fridays</option>
                        <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                        <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                        <option value="Mondays">Mondays</option>
                        <option value="Tuesdays">Tuesdays</option>
                        <option value="Wednesdays">Wednesdays</option>
                        <option value="Thursdays">Thursdays</option>
                        <option value="Fridays">Fridays</option>
                        <option value="Saturdays">Saturdays</option>
                        <option value="Sundays">Sundays</option>
                    </Select>
                    <Input type="time" onChange={(e) => handleAdScheduleChangeF(newIndex, 'startTime', e.target.value)} />
                    <Text>to</Text>
                    <Input type="time" onChange={(e) => handleAdScheduleChangeF(newIndex, 'endTime', e.target.value)} />
                </Flex>
            </Box>
        );
        setBoxesF(prevBoxes => [...prevBoxes, newBox]);
    };

    const handleRemoveButtonClickF = (indexToRemove) => {
        setBoxesF(prevBoxes => prevBoxes.filter((box, index) => index !== indexToRemove));
        setFormData1(prevState => {
            const updatedAdSchedule = prevState.ad_schedule.filter((_, index) => index !== indexToRemove);
            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };

    const initialBoxF = (
        <Box key={0} index={0} mt={0}>
            <Flex alignItems="center" gap={4}>
                <Select
                    onChange={(e) => handleAdScheduleChangeF(0, 'day', e.target.value)}
                    isRequired
                >
                    <option value="">Select Days</option>
                    <option value="All-days">All Days</option>
                    <option value="Mondays-Fridays">Mondays-Fridays</option>
                    <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                    <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                    <option value="Mondays">Mondays</option>
                    <option value="Tuesdays">Tuesdays</option>
                    <option value="Wednesdays">Wednesdays</option>
                    <option value="Thursdays">Thursdays</option>
                    <option value="Fridays">Fridays</option>
                    <option value="Saturdays">Saturdays</option>
                    <option value="Sundays">Sundays</option>
                </Select>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChangeF(0, 'startTime', e.target.value)}
                    isRequired
                />
                <Text>to</Text>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChangeF(0, 'endTime', e.target.value)}
                    isRequired
                />
            </Flex>
        </Box>
    );
    const [boxesF, setBoxesF] = useState([initialBoxF]);

    // let handleSubmit = async () => {
    //     // e.preventDefault();
    //     if (formData.budget === "" || formData.campaign_name === "" ||
    //         formData.campaign_subtype === "" || formData.start_date === "" || formData.end_date === "" ||
    //         formData.location === "" || formData.language === "" || formData.ad_schedule === "" ||
    //         formData.keywords === "" || formData.youtube_video === ""
    //     ) {
    //         toast({
    //             title: 'Error',
    //             description: "Please fill all the details",
    //             status: 'warning',
    //             duration: 9000,
    //             isClosable: true,
    //             position: "top-center"
    //         })
    //     } else {
    //         try {
    //             let response = await fetch(`https://releasesnepal.net/youtube`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 },
    //                 body: JSON.stringify(formData)
    //             })
    //             let res = await response.json();
    //             if (response.ok) {
    //                 await PaymentSend();
    //             }
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }

    const PaymentSendFacebook = async (referenceId) => {
        // Set loading state (optional)
        // setLoading(true);
        // let referenceId = localStorage.getItem("refrenceId")||""
        const paymentData = {
            amount: parseFloat(formData1.budget),
            tax_amount: parseFloat(taxAmount),
            emailuser,
            referenceId
        };

        try {
            const response = await fetch('https://releasesnepal.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            const { formHtml } = await response.json();

            // Replace the current page's content with the form HTML and submit it
            document.body.innerHTML = formHtml;

            // Attach an event listener to call `onPaymentSuccess` after the form is submitted
            const paymentForm = document.forms['paymentForm'];

            paymentForm.addEventListener('submit', async (event) => {
                event.preventDefault(); // Prevent the default form submission
                await handleSubmitFacebook();
                paymentForm.submit(); // Submit the form programmatically
                // Call your success function
            });

            paymentForm.submit(); // Trigger the form submission
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Reset loading state (optional)
            // setLoading(false);
        }
    }

    const handleSubmitFacebook = async () => {
        setLoadF(true)
        if (formData1.budget === "" || formData1.campaign_subtype === "") {
            toast({
                title: 'Error',
                description: "Please fill all the details",
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: "top-center"
            });
            setLoadF(false)
        } else {
            if (formData1.budget >= 500) {
                const form = new FormData();

                Object.keys(formData1).forEach((key) => {
                    const value = formData1[key];
                    if (Array.isArray(value)) {
                        form.append(key, JSON.stringify(value)); // Stringify arrays
                    } else {
                        form.append(key, value);
                    }
                });

                for (let i = 0; i < images.length; i++) {
                    form.append('imagesTosend', images[i]);
                }

                try {
                    const response = await axios.post('https://releasesnepal.net/facebook', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    console.log(response);
                    if (response.statusText) {
                        localStorage.setItem("refrenceId", response.data.data.AcNumber);
                        await PaymentSendFacebook(response.data.data.AcNumber);
                        setLoadF(false)
                    }
                } catch (error) {
                    console.log(error);
                    setLoadF(false)
                }
            } else {
                toast({
                    // title: 'Error',
                    description: "Minimum budget is of NPR 500",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    position: "top-center"
                });
                setLoadF(false)
            }
        }
    };








    //instagram
    const [accordionIsOpenI1, setAccordionIsOpenI1] = useState(false);

    const toggleAccordionI1 = () => {
        setAccordionIsOpenI1(!accordionIsOpenI1);
    };


    const [accordionIsOpenI2, setAccordionIsOpenI2] = useState(false);

    const toggleAccordionI2 = () => {
        setAccordionIsOpenI2(!accordionIsOpenI2);
    };

    const [accordionIsOpenI3, setAccordionIsOpenI3] = useState(false);

    const toggleAccordionI3 = () => {
        setAccordionIsOpenI3(!accordionIsOpenI3);
    };

    const [accordionIsOpenI4, setAccordionIsOpenI4] = useState(false);

    const toggleAccordionI4 = () => {
        setAccordionIsOpenI4(!accordionIsOpenI4);
    };

    const [accordionIsOpenI5, setAccordionIsOpenI5] = useState(false);

    const toggleAccordionI5 = () => {
        setAccordionIsOpenI5(!accordionIsOpenI5);
    };

    const [accordionIsOpenI6, setAccordionIsOpenI6] = useState(false);

    const toggleAccordionI6 = () => {
        setAccordionIsOpenI6(!accordionIsOpenI6);
    };

    const [accordionIsOpenI7, setAccordionIsOpenI7] = useState(false);

    const toggleAccordionI7 = () => {
        setAccordionIsOpenI7(!accordionIsOpenI7);
    };

    const [accordionIsOpenI8, setAccordionIsOpenI8] = useState(false);

    const toggleAccordionI8 = () => {
        setAccordionIsOpenI8(!accordionIsOpenI8);
    };


    const [accordionIsOpenI9, setAccordionIsOpenI9] = useState(false);

    const toggleAccordionI9 = () => {
        setAccordionIsOpenI9(!accordionIsOpenI9);
    };

    const [formDataI, setFormDataI] = useState({
        user_id: user.email,
        campaign_name: 'Ad1',
        ad_platform: "Instagram",
        campaign_subtype: '',
        budget: '',
        start_date: startDate,
        end_date: endDate,
        location: ["Nepal"],
        language: ['Nepali'],
        ad_schedule: [],
        gender: ['Male', 'Female', 'Unknown'], // Default checked values for gender
        age: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'], // Default checked values for age
        parental_status: ['Not-a-parent', 'Parent', 'Unknown'], // Default checked values for parental status
        Householdincome: ['Top10', '11-20', '21-30', '31-40', '41-50', 'Lower50', 'Unknown'], // Default checked values for household income
        keywords: [],
        imagesTosend: [],
        urltoSend: [],
        question: [],
        applink: ""
    });





    console.log(formData)


    const handleCheckboxChangeI = (category, value) => {
        setFormDataI(prevState => {
            // Check if the value is already in the array
            const isChecked = prevState[category].includes(value);
            let updatedCategory;

            if (isChecked) {
                // If the value is already selected, remove it
                updatedCategory = prevState[category].filter(item => item !== value);
            } else {
                // If the value is not selected, add it
                updatedCategory = [...prevState[category], value];
            }

            // Return updated state with the modified category
            return {
                ...prevState,
                [category]: updatedCategory
            };
        });
    };

    const handleKeywordsI = (value) => {
        setFormDataI(prevState => ({
            ...prevState,
            keywords: value.split(',').map(keyword => keyword.trim())
        }));
    };

    const handleAdScheduleChangeI = (index, field, value) => {
        setFormDataI(prevState => {
            const updatedAdSchedule = [...prevState.ad_schedule];
            let updatedEntry = updatedAdSchedule[index];

            // If the entry doesn't exist at the specified index, create a new object
            if (!updatedEntry) {
                updatedEntry = {};
                updatedAdSchedule[index] = updatedEntry;
            }

            // Update the field of the object at the specified index
            updatedEntry[field] = value;

            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };

    const [selectedLocationsI, setSelectedLocationsI] = useState([]);
    const [searchTermI, setSearchTermI] = useState('');
    const [selectedLocationI, setSelectedLocationI] = useState("Nepal");
    const [selectedOptionsI, setSelectedOptionsI] = useState(["Nepali"]);
    const [searchInputI, setSearchInputI] = useState("");
    const [imagesI, setImagesI] = useState([]);
    const [videoUrlsI, setVideoUrlsI] = useState([]);
    const [questionsI, setQuestionsI] = useState(['']);
    const [productImageI, setProductImageI] = useState([]);
    // const [appLinkI, setAppLinkI] = useState('');
    const [errorI, setErrorI] = useState('');

    const handleBudgetChangeI = (e) => {
        const value = e.target.value;
        if (value === '' || parseInt(value) >= 500) {
            setErrorI('');
        } else {
            setErrorI('Minimum Budget is of NPR 500');
        }
        setFormDataI({ ...formDataI, budget: value });
    };


    const handleFormDataChangeI = (value) => {
        setFormDataI(prevState => ({
            ...prevState,
            campaign_subtype: value
        }));
    };

    const handleLocationChangeI = (e) => {
        if (selectedLocationI === "other" && e !== "other") {
            // Clear the selected locations array if switching from "other" to another option
            setSelectedLocationsI([]);
        }
        setSelectedLocationI(e);
        if (e === "other") {
            // If "other" is selected, clear the formData.location array
            setFormDataI(prevData => ({
                ...prevData,
                location: []
            }));
        } else if (e === "all-countries&territories") {
            // If "all-countries&territories" is selected, store only it
            setFormDataI(prevData => ({
                ...prevData,
                location: ["all-countries&territories"]
            }));
        } else if (e === "Nepal") {
            // If "Nepal" is selected, store only it
            setFormDataI(prevData => ({
                ...prevData,
                location: ["Nepal"]
            }));
        }
    };

    const handlePlaceSelectI = () => {
        if (selectedLocationI === "other") {
            const place = autocompleteRef.current.getPlace();
            if (place && place.formatted_address) {
                const newLocation = {
                    id: Math.random().toString(36).substring(7),
                    address: place.formatted_address
                };
                setSelectedLocationsI(prevLocations => [...prevLocations, newLocation]);
                setFormDataI(prevData => ({
                    ...prevData,
                    location: [...prevData.location, newLocation.address]
                }));
                setSearchInputI("");
            }
        } else {
            setSelectedLocationsI([selectedLocationI]);
            setFormDataI(prevData => ({
                ...prevData,
                location: [selectedLocationI]
            }));
        }
    };

    const removeLocationI = (id) => {
        // Filter out the location with the given id
        const updatedLocations = selectedLocationsI.filter(location => location.id !== id);
        // Update the selectedLocations state
        setSelectedLocationsI(updatedLocations);

        // Update the formData with the updated locations
        setFormDataI({
            ...formDataI,
            location: updatedLocations, // Update the location field
        });
    };

    const handleInputChangeI = (e) => {
        setSearchTermI(e.target.value);
    };

    const handleOptionSelectI = (option) => {
        if (selectedOptionsI.includes(option)) {
            setSelectedOptionsI(selectedOptionsI.filter((item) => item !== option));
        } else {
            setSelectedOptionsI([...selectedOptionsI, option]);
        }
    };

    const filteredOptionsI = options.filter((option) =>
        option.toLowerCase().includes(searchTermI.toLowerCase())
    );

    const handleRemoveOptionI = (option) => {
        setSelectedOptionsI(selectedOptionsI.filter((item) => item !== option));
    };

    const handleAddButtonClickI = () => {
        const newIndex = boxesI.length;
        const newBox = (
            <Box key={newIndex} mt={newIndex === 0 ? 0 : 4}>
                <Flex alignItems="center" gap={4}>
                    <Select onChange={(e) => handleAdScheduleChangeI(newIndex, 'day', e.target.value)}>
                        <option value="All-days">All Days</option>
                        <option value="Mondays-Fridays">Mondays-Fridays</option>
                        <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                        <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                        <option value="Mondays">Mondays</option>
                        <option value="Tuesdays">Tuesdays</option>
                        <option value="Wednesdays">Wednesdays</option>
                        <option value="Thursdays">Thursdays</option>
                        <option value="Fridays">Fridays</option>
                        <option value="Saturdays">Saturdays</option>
                        <option value="Sundays">Sundays</option>
                    </Select>
                    <Input type="time" onChange={(e) => handleAdScheduleChangeI(newIndex, 'startTime', e.target.value)} />
                    <Text>to</Text>
                    <Input type="time" onChange={(e) => handleAdScheduleChangeI(newIndex, 'endTime', e.target.value)} />
                </Flex>
            </Box>
        );
        setBoxesI(prevBoxes => [...prevBoxes, newBox]);
    };

    const handleRemoveButtonClickI = (indexToRemove) => {
        setBoxesI(prevBoxes => prevBoxes.filter((box, index) => index !== indexToRemove));
        setFormDataI(prevState => {
            const updatedAdSchedule = prevState.ad_schedule.filter((_, index) => index !== indexToRemove);
            return {
                ...prevState,
                ad_schedule: updatedAdSchedule
            };
        });
    };

    const initialBoxI = (
        <Box key={0} index={0} mt={0}>
            <Flex alignItems="center" gap={4}>
                <Select
                    onChange={(e) => handleAdScheduleChangeI(0, 'day', e.target.value)}
                    isRequired
                >
                    <option value="">Select Days</option>
                    <option value="All-days">All Days</option>
                    <option value="Mondays-Fridays">Mondays-Fridays</option>
                    <option value="Mondays-Saturdays">Mondays-Saturdays</option>
                    <option value="Saturdays-Sundays">Saturdays-Sundays</option>
                    <option value="Mondays">Mondays</option>
                    <option value="Tuesdays">Tuesdays</option>
                    <option value="Wednesdays">Wednesdays</option>
                    <option value="Thursdays">Thursdays</option>
                    <option value="Fridays">Fridays</option>
                    <option value="Saturdays">Saturdays</option>
                    <option value="Sundays">Sundays</option>
                </Select>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChangeI(0, 'startTime', e.target.value)}
                    isRequired
                />
                <Text>to</Text>
                <Input type="time"
                    onChange={(e) => handleAdScheduleChangeI(0, 'endTime', e.target.value)}
                    isRequired
                />
            </Flex>
        </Box>
    );
    const [boxesI, setBoxesI] = useState([initialBoxI]);


    const handleImageChangeI = (e) => {
        const files = Array.from(e.target.files);
        setImagesI(files);
        setFormDataI(prevFormData => ({
            ...prevFormData,
            imagesTosend: files
        }))
    };

    const handleVideoUrlChangeI = (e, index) => {
        const newVideoUrls = [...videoUrlsI];
        newVideoUrls[index] = e.target.value;
        setVideoUrlsI(newVideoUrls);
        setFormDataI(prevFormData => ({
            ...prevFormData,
            urltoSend: newVideoUrls
        }))
    };

    const handleQuestionChangeI = (e, index) => {
        const newQuestions = [...questionsI];
        newQuestions[index] = e.target.value;
        setQuestionsI(newQuestions);
        setFormDataI(prevData => ({
            ...prevData,
            question: newQuestions
        }));
    };

    const handleAddQuestionI = () => {
        if (questionsI.length < 10) {
            setQuestionsI([...questionsI, '']);
        }
    };

    const handleAddVideoUrlI = () => {
        if (videoUrlsI.length < 3) {
            setVideoUrlsI([...videoUrlsI, '']);
        }
    };

    const handleRemoveQuestionI = (index) => {
        const newQuestions = questionsI.filter((_, i) => i !== index);
        setQuestionsI(newQuestions);
    };

    const handleRemoveVideoUrlI = (index) => {
        const newVideoUrls = videoUrlsI.filter((_, i) => i !== index);
        setVideoUrlsI(newVideoUrls);
    };

    const renderImageInputI = () => (
        <Input
            mt={"20px"}
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChangeI}
            required
        />
    );

    const renderVideoUrlInputI = () => (
        <>
            {videoUrlsI.map((url, index) => (
                <InputGroup key={index} size="md" mt={4}>
                    <Input
                        type="url"
                        placeholder="Enter video URL"
                        value={url}
                        onChange={(e) => handleVideoUrlChangeI(e, index)}
                        required
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="2.1rem" mr={'5px'} color={"white"} bg={"red"} size="sm" onClick={() => handleRemoveVideoUrlI(index)}>
                            Remove
                        </Button>
                    </InputRightElement>
                </InputGroup>
            ))}
            {videoUrlsI.length < 3 && (
                <Button mt={4} color={"white"} bg={"rgb(24,112,229)"} size={"md"} onClick={handleAddVideoUrlI}>Add Video URL</Button>
            )}
        </>
    );

    const renderQuestionsI = () => (
        <>
            {questionsI.map((ques, index) => (
                <Box key={index} mt={"10px"}>
                    <Textarea
                        placeholder="Enter question"
                        value={ques}
                        onChange={(e) => handleQuestionChangeI(e, index)}
                        required
                    />
                    <Button size={"sm"} mt={"10px"} color={"white"} bg={"red"} onClick={() => handleRemoveQuestionI(index)}>Remove</Button>
                </Box>
            ))}
            {questionsI.length < 10 && (
                <Button color={"white"} mt={"20px"} bg={"rgb(24,112,229)"} onClick={handleAddQuestionI}>Add Question</Button>
            )}
        </>
    );
    let [loadI, setLoadI] = useState(false)

    const PaymentSendInstagram = async (referenceId) => {
        // Set loading state (optional)
        // setLoading(true);
        // let referenceId = localStorage.getItem("refrenceId")||""
        const paymentData = {
            amount: parseFloat(formDataI.budget),
            tax_amount: parseFloat(taxAmount),
            emailuser,
            referenceId
        };

        try {
            const response = await fetch('https://releasesnepal.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            const { formHtml } = await response.json();

            // Replace the current page's content with the form HTML and submit it
            document.body.innerHTML = formHtml;

            // Attach an event listener to call `onPaymentSuccess` after the form is submitted
            const paymentForm = document.forms['paymentForm'];

            paymentForm.addEventListener('submit', async (event) => {
                event.preventDefault(); // Prevent the default form submission
                await handleSubmitInstagram();
                paymentForm.submit(); // Submit the form programmatically
                // Call your success function
            });

            paymentForm.submit(); // Trigger the form submission
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Reset loading state (optional)
            // setLoading(false);
        }
    }

    const handleSubmitInstagram = async () => {
        setLoadG(true)
        if (formDataI.budget === "" || formDataI.campaign_subtype === "") {
            toast({
                title: 'Error',
                description: "Please fill all the details",
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: "top-center"
            });
            setLoadG(false)
        } else {
            if (formDataI.budget >= 500) {
                const form = new FormData();

                Object.keys(formDataI).forEach((key) => {
                    const value = formDataI[key];
                    if (Array.isArray(value)) {
                        form.append(key, JSON.stringify(value)); // Stringify arrays
                    } else {
                        form.append(key, value);
                    }
                });

                for (let i = 0; i < imagesI.length; i++) {
                    form.append('imagesTosend', imagesI[i]);
                }

                try {
                    const response = await axios.post('https://releasesnepal.net/instagram', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    console.log(response);
                    if (response.statusText) {
                        localStorage.setItem("refrenceId", response.data.data.AcNumber);
                        await PaymentSendInstagram(response.data.data.AcNumber);
                        setLoadG(false)
                    }
                } catch (error) {
                    console.log(error);
                    setLoadG(false)
                }
            } else {
                toast({
                    // title: 'Error',
                    description: "Minimum budget is of NPR 500",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    position: "top-center"
                });
                setLoadG(false)
            }

        }
    };

    //FOR GOOGLE
    const [accordionIsOpenG1, setAccordionIsOpenG1] = useState(false);

    const toggleAccordionG1 = () => {
        setAccordionIsOpenG1(!accordionIsOpenG1);
    };


    const [accordionIsOpenG2, setAccordionIsOpenG2] = useState(false);

    const toggleAccordionG2 = () => {
        setAccordionIsOpenG2(!accordionIsOpenG2);
    };

    const [accordionIsOpenG3, setAccordionIsOpenG3] = useState(false);

    const toggleAccordionG3 = () => {
        setAccordionIsOpenG3(!accordionIsOpenG3);
    };

    const [accordionIsOpenG4, setAccordionIsOpenG4] = useState(false);

    const toggleAccordionG4 = () => {
        setAccordionIsOpenG4(!accordionIsOpenG4);
    };

    const [accordionIsOpenG5, setAccordionIsOpenG5] = useState(false);

    const toggleAccordionG5 = () => {
        setAccordionIsOpenG5(!accordionIsOpenG5);
    };

    const [accordionIsOpenG6, setAccordionIsOpenG6] = useState(false);

    const toggleAccordionG6 = () => {
        setAccordionIsOpenG6(!accordionIsOpenG6);
    };

    const [accordionIsOpenG7, setAccordionIsOpenG7] = useState(false);

    const toggleAccordionG7 = () => {
        setAccordionIsOpenG7(!accordionIsOpenG7);
    };

    const [accordionIsOpenG8, setAccordionIsOpenG8] = useState(false);

    const toggleAccordionG8 = () => {
        setAccordionIsOpenG8(!accordionIsOpenG8);
    };


    const [accordionIsOpenG9, setAccordionIsOpenG9] = useState(false);

    const toggleAccordionG9 = () => {
        setAccordionIsOpenG9(!accordionIsOpenG9);
    };

    const [formDataG, setFormDataG] = useState({
        ad_platform: "Gooogle",
        user_id: user.email,
        campaign_subtype: '',
        title: '',
        description: '',
        budget: '',
        weblink: '',
        store_location: '',
        profile_url: ""

    });
    let [loadG, setLoadG] = useState(false)
    console.log(formDataG)

    const handleFormDataChangeG = (value) => {
        setFormDataG(prevState => ({
            ...prevState,
            campaign_subtype: value
        }));
    };


    const [errorG, setErrorG] = useState('');

    const handleBudgetChangeG = (e) => {
        const value = e.target.value;
        if (value === '' || parseInt(value) >= 500) {
            setErrorG('');
        } else {
            setErrorG('Minimum Budget is of NPR 500');
        }
        setFormDataG({ ...formDataG, budget: value });
    };



    const PaymentSendGoogle = async (referenceId) => {
        // Set loading state (optional)
        // setLoading(true);
        // let referenceId = localStorage.getItem("refrenceId")||""
        const paymentData = {
            amount: parseFloat(formDataG.budget),
            tax_amount: parseFloat(taxAmount),
            emailuser,
            referenceId
        };

        try {
            const response = await fetch('https://releasesnepal.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            const { formHtml } = await response.json();

            // Replace the current page's content with the form HTML and submit it
            document.body.innerHTML = formHtml;

            // Attach an event listener to call `onPaymentSuccess` after the form is submitted
            const paymentForm = document.forms['paymentForm'];

            paymentForm.addEventListener('submit', async (event) => {
                event.preventDefault(); // Prevent the default form submission
                await handleSubmitInstagram();
                paymentForm.submit(); // Submit the form programmatically
                // Call your success function
            });

            paymentForm.submit(); // Trigger the form submission
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Reset loading state (optional)
            // setLoading(false);
        }
    }

    const handleSubmitGoogle = async () => {
        setLoadG(true)
        if (formDataG.budget === "" || formDataG.campaign_subtype === "") {
            toast({
                title: 'Error',
                description: "Please fill all the details",
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: "top-center"
            });
            setLoadG(false)
        } else {

            if (formDataG.budget >= 500) {
                try {
                    const response = await axios.post('https://releasesnepal.net/google', formDataG, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    console.log(response);

                    localStorage.setItem("refrenceId", response.data.data.AcNumber);
                    await PaymentSendGoogle(response.data.data.AcNumber);
                    setLoadG(false)

                } catch (error) {
                    console.log(error);
                    setLoadG(false)
                }
            } else {
                toast({
                    // title: 'warning',
                    description: "Minimum budget is of NPR 500",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    position: "top-center"
                });
                setLoadG(false)
            }
        }
    };






















    function formatNumberWithSuffix(num) {
        const suffixes = ["", "K", "M", "B", "T"];
        const tier = Math.log10(Math.abs(num)) / 3 | 0;

        if (tier === 0) return num;

        const suffix = suffixes[tier];
        const scale = Math.pow(10, tier * 3);

        const scaled = num / scale;

        return scaled.toFixed(0) + suffix;
    }


    const handleRemoveVideo = (index) => {
        const newSelectedVideos = selectedVideos.filter((_, i) => i !== index);
        setSelectedVideos(newSelectedVideos); // Update the state with the new list
    };


    // if (!isLoaded) {
    //     return <div>Loading Maps</div>;
    // }
    console.log(formDataI)
    return (
        <>
            <Box className="mainbox">
                <Box position={"sticky"} zIndex={100}>
                    <Navbar />
                </Box>
                <Box >
                    <Box position={"static"}>
                        <Flex direction={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                            <Box className="custom-scrollbar" w={{ base: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" }} p={"0px 20px 20px 20px"} zIndex={10} boxShadow={{ base: "", sm: "", md: "", lg: "rgba(0, 0, 0, 0.16) 0px 1px 4px;", xl: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }} overflowY="auto" flex="1" maxHeight="calc(100vh - 40px)">
                                <Box>
                                    <Box mt={"20px"}>
                                        <Flex gap={"40px"} justifyContent={"center"} >
                                            <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                                <i
                                                    style={{ color: '#f60002', }}
                                                    className="fa-brands fa-youtube fa-3x"
                                                    onClick={() => handleIconClick('youtube')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'youtube' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>
                                            <Box display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}>
                                                <i
                                                    style={{ color: '#f60002', }}
                                                    className="fa-brands fa-youtube fa-2x"
                                                    onClick={() => handleIconClick('youtube')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'youtube' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>
                                            <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                                <i style={{ color: "#1974ec", }} class="fa-brands fa-facebook fa-3x"
                                                    onClick={() => handleIconClick('facebook')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'facebook' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>
                                            <Box display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}>
                                                <i style={{ color: "#1974ec", }} class="fa-brands fa-facebook fa-2x"
                                                    onClick={() => handleIconClick('facebook')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'facebook' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>
                                            <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                                <i style={{ color: "#af388f", }} class="fa-brands fa-instagram fa-3x"
                                                    onClick={() => handleIconClick('instagram')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'instagram' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>
                                            <Box display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}>
                                                <i style={{ color: "#af388f", }} class="fa-brands fa-instagram fa-2x"
                                                    onClick={() => handleIconClick('instagram')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'instagram' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>

                                            <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                                <i style={{ color: "#fbbc05" }} class="fa-brands fa-google fa-3x"
                                                    onClick={() => handleIconClick('google')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'google' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>

                                            <Box display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}>
                                                <i style={{ color: "#fbbc05" }} class="fa-brands fa-google fa-2x"
                                                    onClick={() => handleIconClick('google')}
                                                ></i>
                                                <Divider mt={"5px"} display={activeSocialMedia === 'google' ? 'block' : 'none'} border={"2px solid blue"} />
                                            </Box>

                                        </Flex>
                                    </Box>

                                    <Box>
                                        {
                                            activeSocialMedia === "youtube" && (
                                                <form
                                                // onSubmit={handleSubmit}
                                                >
                                                    <Box>

                                                        {/* <Box w={"30%"} m={"auto"} mt={"30px"} position={"relative"} _hover={{ transition: "transform 0.3s ease-in-out", transform: "scale(1.05)" }} >
                                            <Box borderRadius={"2px"} border={"1px solid blue"} cursor={"pointer"}>
                                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box w={"50%"} position="relative">
                                                        <Image w={"90%"} src={video} />
                                                    </Box>
                                                    <Box w={"60%"} textAlign={"left"}>
                                                        <Text fontSize={"16px"} fontWeight={"600"}>Video</Text>
                                                        <Text fontSize={"14px"}>Reach Viewers on YouTube and get conversions</Text>
                                                    </Box>
                                                    <Box color={"blue"} bg={"white"} zIndex={10} position={"absolute"} top={"-10px"} right={"-5px"} textAlign={"right"}>
                                                        <i class="fa-regular fa-circle-check fa-lg"></i>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box> */}
                                                        <Box mt={"30px"} mb={accordionIsOpen3 ? "0px" : "30px"}
                                                        >
                                                            <Accordion defaultIndex={[0]} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion3} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Advertisement type</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Box>
                                                                            <Text ml={"10px"} color={"rgb(112,113,115)"}>Select advertisement type
                                                                            </Text>
                                                                            <RadioGroup defaultValue="Video-Views" value={formData.campaign_subtype} onChange={handleFormDataChange} isRequired>
                                                                                <Box ml={"10px"}>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' value='Video-Views' >
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Video-Views</Text>
                                                                                                <Text w={"80%"}>Get views and engagement from people who are more likely to consider your products or brand. You only pay when someone chooses to watch your ad. Your ads can show as skippable in-stream, in-feed, and Shorts ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/13982458?hl=en&_gl=1*1amir1j*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Efficient-reach'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Efficient-reach</Text>
                                                                                                <Text w={"80%"}>Get the most reach for your budget using bumper, skippable in-stream, in-feed, and Shorts ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10510238?hl=en&_gl=1*1geaso8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#efficient-reach" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Target-frequency'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Target-frequency</Text>
                                                                                                <Text w={"80%"}>Reach the same people more than once with skippable in-stream ads, non-skippable in-stream ads, or bumper ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12400225?hl=en&_gl=1*9k1q6f*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Non-skippable-reach'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Non-skippable-reach</Text>
                                                                                                <Text w={"80%"}>Reach people using up to 15-second non-skippable in-stream ads. <a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/2375497?hl=en&_gl=1*1sxkj8z*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Drive-conversions'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Drive-conversions</Text>
                                                                                                <Text w={"80%"}>Get more conversions with video ads designed to encourage valuable interactions with your business <a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10146226?hl=en&_gl=1*tdwqni*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Ad-sequence'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Ad-sequence</Text>
                                                                                                <Text w={"80%"}>Tell your story by showing ads in a particular sequence to individual viewers with skippable in-stream ads, non-skippable in-stream ads, bumper ads, or a mix.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/9161595?hl=en&_gl=1*1iubhq8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Audio'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Audio</Text>
                                                                                                <Text w={"80%"}>Reach your audience while they're listening on YouTube with audio-based ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10227746?hl=en&_gl=1*18uc862*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='YouTube-engagements'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>YouTube-engagements</Text>
                                                                                                <Text w={"80%"}>Get subscriptions and drive engagement on your YouTube channel with video ads designed to encourage valuable interactions.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpen2 ? "30px" : "0px"} mb={accordionIsOpen2 ? "30px" : "0px"}
                                                        >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion2} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}> Campaign name</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} >
                                                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                            <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formData.campaign_name} fontSize={"18px"} maxLength={50}
                                                                                onChange={(e) => setFormData({ ...formData, campaign_name: e.target.value })}
                                                                                placeholder="Enter campaign name"
                                                                            />
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpen4 ? "30px" : "0px"}
                                                            mb={accordionIsOpen4 ? "30px" : "0px"} >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion4} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Budget and dates</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Text pl={"6px"} mt={"-5px"} color={"rgb(112,113,115)"}>Enter your total budget</Text>
                                                                        <Box p={"10px"}>
                                                                            <Flex justifyContent={"space-between"} alignItems="center">
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} flex={"1"} p={"10px"}>
                                                                                    <Input
                                                                                        h={"40px"}
                                                                                        p={"10px"}
                                                                                        w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }}
                                                                                        fontSize={"18px"}
                                                                                        name="budget"
                                                                                        value={formData.budget}
                                                                                        onChange={handleBudgetChange}
                                                                                        placeholder="Enter your total budget"
                                                                                        maxLength={50}
                                                                                        isInvalid={!!error} // Apply Chakra's invalid styling
                                                                                    />
                                                                                    {error && (
                                                                                        <Text color="red.500" mt={2}>
                                                                                            {error}
                                                                                        </Text>
                                                                                    )}
                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                        <Box p={"10px"} >
                                                                            <Box mt={"20px"} p={"10px"} border={"1px solid #eee"}>
                                                                                <Flex justifyContent={"space-between"} direction={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }} gap={{ base: "5px", sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>Start date</Text>
                                                                                        <Input h={"30px"} type="date" name="start_date" value={formData.start_date}
                                                                                            onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>End date</Text>
                                                                                        <Input h={"30px"} type="date" name="end_date" value={formData.end_date}
                                                                                            onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}

                                                                                        />
                                                                                    </Box>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        {/* <Button w={"80%"} bg={"rgb(24,112,229)"} borderRadius={"10px"} p={"10px"} border={"none"} color={"white"}>Next</Button> */}

                                                    </Box>

                                                    <Box mt={accordionIsOpen5 ? "30px" : "0px"} mb={accordionIsOpen5 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion5} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Locations</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select locations to target</Text>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"

                                                                                    value={"all-countries&territories"}
                                                                                    checked={selectedLocation === "all-countries&territories"}
                                                                                    onChange={() => handleLocationChange("all-countries&territories")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired

                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>All countries and territories</label>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <Box >
                                                                                    <input
                                                                                        type="radio"
                                                                                        value="Nepal" // Set the value directly here
                                                                                        checked={selectedLocation === "Nepal"}
                                                                                        onChange={() => handleLocationChange("Nepal")} // Directly set selectedLocation
                                                                                        style={{ width: "16px", height: "16px" }}
                                                                                    // isRequired
                                                                                    />
                                                                                    <label style={{ fontSize: "18px", marginLeft: "5px" }}>Nepal</label>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value={"other"}
                                                                                    checked={selectedLocation === "other"}
                                                                                    onChange={() => handleLocationChange("other")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired
                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>Enter another location</label>
                                                                                {selectedLocation === "other" && (
                                                                                    <Box ml={"20px"} mt={"20px"}>

                                                                                        <Autocomplete
                                                                                            onLoad={autocomplete => autocompleteRef.current = autocomplete}
                                                                                            onPlaceChanged={handlePlaceSelect}
                                                                                        >
                                                                                            <Input
                                                                                                h={"40px"}
                                                                                                p={"10px"}
                                                                                                w={"80%"}
                                                                                                fontSize={"18px"}
                                                                                                placeholder="Enter location"
                                                                                                type="text"
                                                                                                onChange={e => setSearchInput(e.target.value)}
                                                                                                value={searchInput}
                                                                                            />
                                                                                        </Autocomplete>
                                                                                    </Box>
                                                                                )}
                                                                                {selectedLocation === "other" && (
                                                                                    <Box>
                                                                                        {selectedLocations.length > 0 && (
                                                                                            <Box mt={4}>
                                                                                                <Text fontWeight="bold">Selected Locations:</Text>
                                                                                                <Box mt={"20px"} border={"1px solid #eee"} p={"10px 20px 10px 20px"}>
                                                                                                    {selectedLocations.map(location => (
                                                                                                        <Flex key={location.id} backgroundColor={"rgba(60, 64, 67, .04)"} p={"10px"} alignItems="center" justifyContent={"space-between"} mt={"10px"} mb={"10px"}>
                                                                                                            <Text>{location.address}</Text>
                                                                                                            <Button ml={4} size="xs" onClick={() => removeLocation(location.id)}><CloseIcon></CloseIcon></Button>
                                                                                                        </Flex>
                                                                                                    ))}
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                            </Box>

                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    <Box mt={accordionIsOpen6 ? "30px" : "0px"} mb={accordionIsOpen6 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion6} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Languages</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select the languages your customers speak.</Text>
                                                                            <Box mt={"10px"}>
                                                                                <Box
                                                                                    className={`select ${openModal1 ? "open" : ""}`}
                                                                                    onClick={onOpen1}
                                                                                    p={2}
                                                                                    border="1px solid"
                                                                                    borderRadius="md"
                                                                                    cursor="pointer"
                                                                                >
                                                                                    <Text>
                                                                                        {selectedOptions.length > 0 ? "Select language" : "Select language"}
                                                                                    </Text>
                                                                                </Box>

                                                                                <Modal isOpen={openModal1} onClose={onModalClose1}>
                                                                                    <ModalOverlay />
                                                                                    <ModalContent>
                                                                                        <ModalHeader>Select the languages</ModalHeader>
                                                                                        <ModalCloseButton />
                                                                                        <ModalBody>
                                                                                            <Box mt={2}>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    placeholder="Search languages..."
                                                                                                    value={searchTerm}
                                                                                                    onChange={handleInputChange}

                                                                                                />
                                                                                                <Flex mt={"10px"} direction="column">
                                                                                                    {filteredOptions.map((option) => (
                                                                                                        <Checkbox
                                                                                                            key={option}
                                                                                                            isChecked={selectedOptions.includes(option)}
                                                                                                            onChange={() => handleOptionSelect(option)}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </Checkbox>
                                                                                                    ))}
                                                                                                </Flex>
                                                                                            </Box>
                                                                                        </ModalBody>

                                                                                        <ModalFooter>
                                                                                            <Button colorScheme='blue' mr={3} onClick={onModalClose1}>
                                                                                                Close
                                                                                            </Button>

                                                                                        </ModalFooter>
                                                                                    </ModalContent>
                                                                                </Modal>

                                                                                <Box mt={2}>
                                                                                    {
                                                                                        selectedOptions.length > 0 ? <Box>  <Text>Selected Options:</Text>
                                                                                            <Flex gap={"10px"}>
                                                                                                <Wrap mt={2} spacing="10px">
                                                                                                    {selectedOptions.map((option) => (
                                                                                                        <WrapItem key={option}>
                                                                                                            <Flex _hover={{ background: "rgba(60, 64, 67, .04)" }} borderRadius={"20px"} mt={"10px"} border={"2px solid #eee"} p={"5px 20px 5px 20px"} key={option} alignItems="center">
                                                                                                                <Text>{option}</Text>
                                                                                                                <CloseIcon
                                                                                                                    ml={2}
                                                                                                                    cursor={"pointer"}
                                                                                                                    style={{ fontSize: "8px" }}
                                                                                                                    size="xs"
                                                                                                                    colorScheme="red"
                                                                                                                    onClick={() => handleRemoveOption(option)}
                                                                                                                >
                                                                                                                    Remove
                                                                                                                </CloseIcon>
                                                                                                            </Flex>
                                                                                                        </WrapItem>
                                                                                                    ))}
                                                                                                </Wrap>
                                                                                            </Flex>

                                                                                        </Box>
                                                                                            : null

                                                                                    }

                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    {/* ad scedule */}
                                                    <Box mt={accordionIsOpen7 ? "30px" : "0px"} mb={accordionIsOpen7 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion7} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Ad schedule</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box>
                                                                        {boxes.map((box, index) => (
                                                                            <Flex key={index} mt={index === 0 ? 0 : 4} alignItems="center" gap={"20px"}>
                                                                                {box}{/* Render box without wrapping it in another Box */}
                                                                                {index !== 0 && (
                                                                                    <CloseIcon cursor={"pointer"} colorScheme="red" onClick={() => handleRemoveButtonClick(index)}>
                                                                                        Remove
                                                                                    </CloseIcon>
                                                                                )}
                                                                            </Flex>
                                                                        ))}
                                                                        <Button mt={4} bg={"rgb(24,112,229)"} w={"15%"} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }} color={"white"} onClick={handleAddButtonClick}>
                                                                            ADD
                                                                        </Button>
                                                                    </Box>
                                                                    {/* <Box >

                                                    
                                                    <Box>
                                                        <Flex alignItems={"center"} gap={"20px"}>
                                                            <Select>
                                                                <option value="">All Days</option>
                                                                <option value="">Mondays-Fridays</option>
                                                                <option value="">Saturdays-Sundays</option>
                                                                <option value="">Mondays</option>
                                                                <option value="">Tuesdays</option>
                                                                <option value="">Wednesdays</option>
                                                                <option value="">Thrusdays</option>
                                                                <option value="">Fridays</option>
                                                                <option value="">Saturdays</option>
                                                                <option value="">Sundays</option>
                                                            </Select>
                                                            <Input type="time" />
                                                            <Text>to</Text>
                                                            <Input type="time" />
                                                        </Flex>
                                                    </Box>
                                                    <Button mt={"20px"}>ADD</Button>
                                                    </Box> */}



                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>

                                                    <Box mt={accordionIsOpen1 ? "30px" : "0px"} mb={accordionIsOpen1 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2 >
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion1} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Demographics</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>
                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Select your demographic targeting
                                                                        </Text>

                                                                        <Box mt={"20px"} >
                                                                            <Flex direction={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "25%", xl: "25%" }} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Gender</Text>

                                                                                    <Box>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Male" defaultChecked={true} id="Male" onChange={() => handleCheckboxChange('gender', 'Male')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Male">Male</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="female" defaultChecked={true} id="female" onChange={() => handleCheckboxChange('gender', 'Female')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="female">Female</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="unknown" onChange={() => handleCheckboxChange('gender', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>



                                                                                </Box>
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "25%", xl: "25%" }} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Age</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="18-24" defaultChecked={true} id="18-24" onChange={() => handleCheckboxChange('age', '18-24')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="18-24">18-24</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="25-34" defaultChecked={true} id="25-34" onChange={() => handleCheckboxChange('age', '25-34')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="25-34">25-34</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="35-44" defaultChecked={true} id="35-44" onChange={() => handleCheckboxChange('age', '35-44')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="35-44">35-44</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="45-54" defaultChecked={true} id="45-54" onChange={() => handleCheckboxChange('age', '45-54')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="45-54">45-54</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="55-64" defaultChecked={true} id="55-64" onChange={() => handleCheckboxChange('age', '55-64')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="55-64">55-64</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="65+" defaultChecked={true} id="65+" onChange={() => handleCheckboxChange('age', '65+')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="65+">65+</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChange('age', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                </Box>
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "25%", xl: "25%" }} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Parental status</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Not-a-parent" defaultChecked={true} id="Not-a-parent" onChange={() => handleCheckboxChange('parental_status', 'Not-a-parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Not-a-parent">Not a parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Parent" defaultChecked={true} id="Parent" onChange={() => handleCheckboxChange('parental_status', 'Parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Parent">Parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChange('parental_status', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "25%", xl: "25%" }} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Household income</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Top10" defaultChecked={true} id="Top10" onChange={() => handleCheckboxChange('Householdincome', 'Top10')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Top10">Top 10%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="11-20" defaultChecked={true} id="11-20" onChange={() => handleCheckboxChange('Householdincome', '11-20')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="11-20">11-20%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="21-30" defaultChecked={true} id="21-30" onChange={() => handleCheckboxChange('Householdincome', '21-30')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="21-30">21-30%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="31-40" defaultChecked={true} id="31-40" onChange={() => handleCheckboxChange('Householdincome', '31-40')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="31-40">31-40%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="41-50" defaultChecked={true} id="41-50" onChange={() => handleCheckboxChange('Householdincome', '41-50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="41-50">41-50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Lower50" defaultChecked={true} id="Lower50" onChange={() => handleCheckboxChange('Householdincome', 'Lower50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Lower50">Lower 50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChange('Householdincome', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box ml={"10px"} mt={"20px"} p={"15px"} bg={"rgb(254,247,224)"} borderRadius={"10px"}>
                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                            <i class="fa-solid fa-circle-info"></i>
                                                                            <Text>Note: Household income targeting is only available in select countries.</Text>
                                                                            <a href={"https://support.google.com/google-ads/answer/2580383?hl=en&_gl=1*rcsn05*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA."} style={{ color: "rgb(24,112,229)", textDecoration: "underline" }}  >Learn more</a>
                                                                        </Flex>
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>


                                                    <Box mt={accordionIsOpen8 ? "30px" : "0px"} mb={accordionIsOpen8 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion8} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Keywords</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Choose terms related to your products or services to target relevant content</Text>
                                                                        <Textarea h={"200px"} placeholder="Enter or paste keywords. You can separate each keyword by commas or enter one per line." mt={"20px"} onChange={(e) => handleKeywords(e.target.value)} required />
                                                                    </Box>




                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>
                                                    <Box mt={accordionIsOpen9 ? "30px" : "0px"} mb={accordionIsOpen9 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion9} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Your YouTube Video</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>
                                                                    <Text>Add up to 5 videos. Improve your campaign performance by using videos with different orientations.</Text>
                                                                    <Box mt={"20px"} mb={"30px"}>
                                                                        {selectedVideos.length <= 5 && (
                                                                            selectedVideos.map((ele, idx) => (
                                                                                <Box position="relative" p={"20px"} borderBottom={"1px solid #eee"} h={"13vh"} key={idx} _hover={{ background: "rgba(60, 64, 67, .04)" }}>
                                                                                    <Flex gap={"20px"} alignItems={"center"}>
                                                                                        <Image w={"10%"} src={ele.snippet.thumbnails.high.url} />
                                                                                        <Box>
                                                                                            <Text fontWeight={"bold"}>{ele.snippet.title}</Text>
                                                                                            <Text fontSize={"12px"}>{ele.snippet.description}</Text>
                                                                                        </Box>
                                                                                    </Flex>

                                                                                    {/* Remove button */}
                                                                                    <Box position="absolute" top="5px" right="5px">
                                                                                        <i className="fas fa-times" style={{ cursor: 'pointer' }} onClick={() => handleRemoveVideo(idx)} >

                                                                                        </i>
                                                                                    </Box>
                                                                                </Box>
                                                                            ))
                                                                        )}
                                                                    </Box>



                                                                    <Box mt={"20px"}>
                                                                        <Input name="search" display={selectedVideos.length === 5 ? "none" : "block"} value={search} onChange={handleChangeYoutube} onInput={() => debounce(main)} />
                                                                    </Box>
                                                                    <Box boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" borderRadius={"10px"} style={{ scrollbarColor: '#ccc #eee', scrollbarWidth: "thin" }} maxWidth={"100%"} overflowY={"auto"} maxHeight={"300px"}>
                                                                        {
                                                                            selectedVideos.length === 5 ? null :
                                                                                (
                                                                                    youtube.map((ele, idx) => {
                                                                                        return (
                                                                                            <Box onClick={() => handleVideo(ele)} p={"20px"} borderBottom={"1px solid #eee"} h={"13vh"} key={idx} _hover={{ background: "rgba(60, 64, 67, .04)" }}>
                                                                                                <Flex gap={"20px"} alignItems={"center"}>
                                                                                                    <Image w={"10%"} src={ele.snippet.thumbnails.high.url} />
                                                                                                    <Box>
                                                                                                        <Text fontWeight={"bold"}>{ele.snippet.title}</Text>
                                                                                                        <Text fontSize={"12px"}>{ele.snippet.description}</Text>
                                                                                                    </Box>
                                                                                                </Flex>
                                                                                            </Box>
                                                                                        );
                                                                                    })
                                                                                )
                                                                        }
                                                                    </Box>



                                                                </AccordionPanel>
                                                            </AccordionItem>



                                                        </Accordion>
                                                    </Box>
                                                    <input type="hidden" value={transactionUUID} />
                                                    <input type="hidden" value={signature} />
                                                    <Box mt={"20px"} textAlign={"left"}>
                                                        {
                                                            loadY ? <Button bg={"rgb(24,112,229)"} color={"white"}
                                                                // onClick={handleSubmit}
                                                                // type="submit"
                                                                _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}><Spinner /></Button> :
                                                                <Button bg={"rgb(24,112,229)"} color={"white"}
                                                                    onClick={handleSubmit}
                                                                    // type="submit"
                                                                    _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}>Create & Pay</Button>
                                                        }
                                                    </Box>
                                                </form>
                                            )
                                        }
                                        {
                                            activeSocialMedia === "facebook" && (
                                                <form >
                                                    <Box>


                                                        <Box mt={accordionIsOpenf2 ? "30px" : "30px"} mb={accordionIsOpenf3 ? "0px" : "30px"}
                                                        >
                                                            <Accordion defaultIndex={[0]} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf3} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Advertisement type</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Box>
                                                                            <Text ml={"10px"} color={"rgb(112,113,115)"}>Select advertisement type
                                                                            </Text>
                                                                            <RadioGroup value={formData1.campaign_subtype} onChange={handleFormDataChangef} isRequired>
                                                                                <Box ml={"10px"}>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Lead Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Lead Ads</Text>
                                                                                                <Text w={"80%"}>Ads designed to collect contact information from users directly within the Facebook platform.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Page-likes'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Page-likes</Text>
                                                                                                <Text w={"80%"}>Get the most reach for your budget using bumper, skippable in-stream, in-feed, and Shorts ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10510238?hl=en&_gl=1*1geaso8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#efficient-reach" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Image Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Image Ads</Text>
                                                                                                <Text w={"80%"}>Simple ads that use a single image and can include text, a link, and a call-to-action (CTA) button.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12400225?hl=en&_gl=1*9k1q6f*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' value='Video-Ads' >
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Video-Ads</Text>
                                                                                                <Text w={"80%"}>Ads that use video content. These can appear in the News Feed, Stories, and other placements.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/13982458?hl=en&_gl=1*1amir1j*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Carousel Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Carousel Ads</Text>
                                                                                                <Text w={"80%"}>Ads that allow users to swipe through multiple images or videos within a single ad, each with its own link. <a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/2375497?hl=en&_gl=1*1sxkj8z*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Slideshow Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Slideshow Ads</Text>
                                                                                                <Text w={"80%"}>Lightweight video ads that are created using a series of still images, text, and sound.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10146226?hl=en&_gl=1*tdwqni*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Collection Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Collection Ads</Text>
                                                                                                <Text w={"80%"}>Ads that showcase multiple products and open into a full-screen mobile experience.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/9161595?hl=en&_gl=1*1iubhq8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Instant Experience (Canvas) Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Instant Experience (Canvas) Ads</Text>
                                                                                                <Text w={"80%"}>Full-screen, interactive ads that load instantly on mobile devices. They can include a mix of images, video, text, and links.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10227746?hl=en&_gl=1*18uc862*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Dynamic Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Dynamic Ads</Text>
                                                                                                <Text w={"80%"}>Ads that automatically show the right products to people who have expressed interest on your website, in your app, or elsewhere on the internet.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Messenger Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Messenger Ads</Text>
                                                                                                <Text w={"80%"}>Ads that appear in the Messenger app. These can start a conversation with your business directly in the Messenger app.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Stories Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Stories Ads</Text>
                                                                                                <Text w={"80%"}>Full-screen ads that appear between user stories on Facebook, Instagram, and Messenger.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Playable Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Playable Ads</Text>
                                                                                                <Text w={"80%"}>Interactive ads that allow users to play a game or interact with the ad content before downloading an app.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Poll Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Poll Ads</Text>
                                                                                                <Text w={"80%"}>Ads that include interactive polls, allowing users to engage with the ad by answering questions.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Event Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Event Ads</Text>
                                                                                                <Text w={"80%"}>Ads that promote an event and can help drive attendance by showing details such as the date, time, and location.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Offer Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Offer Ads</Text>
                                                                                                <Text w={"80%"}>Ads that promote a discount or special offer and can include a CTA to redeem the offer.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenf2 ? "30px" : "0px"} mb={accordionIsOpenf2 ? "30px" : "0px"}
                                                        >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf2} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}> Campaign name</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} >
                                                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                            <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formData1.campaign_name} fontSize={"18px"} maxLength={50}
                                                                                onChange={(e) => setFormData1({ ...formData1, campaign_name: e.target.value })}
                                                                                placeholder="Enter campaign name"
                                                                                isRequired />
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenf4 ? "30px" : "0px"}
                                                            mb={accordionIsOpenf4 ? "30px" : "0px"} >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf4} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Budget and dates</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Text pl={"6px"} mt={"-5px"} color={"rgb(112,113,115)"}>Enter your total budget</Text>
                                                                        <Box p={"10px"}>
                                                                            <Flex justifyContent={"space-between"} alignItems="center">
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} flex={"1"} p={"10px"}>
                                                                                    <Input
                                                                                        h={"40px"}
                                                                                        p={"10px"}
                                                                                        w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }}
                                                                                        fontSize={"18px"}
                                                                                        name="budget"
                                                                                        value={formData1.budget}
                                                                                        onChange={handleBudgetChangeF}
                                                                                        placeholder="Enter your total budget"
                                                                                        maxLength={50}
                                                                                        isInvalid={!!error1} // Apply Chakra's invalid styling
                                                                                    />
                                                                                    {error1 && (
                                                                                        <Text color="red.500" mt={2}>
                                                                                            {error1}
                                                                                        </Text>
                                                                                    )}
                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                        <Box p={"10px"} >
                                                                            <Box mt={"20px"} p={"10px"} border={"1px solid #eee"}>
                                                                                <Flex justifyContent={"space-between"} gap={"30px"}>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>Start date</Text>
                                                                                        <Input h={"30px"} type="date" name="start_date" value={formData1.start_date}
                                                                                            onChange={(e) => setFormData1({ ...formData1, start_date: e.target.value })}
                                                                                            isRequired />
                                                                                    </Box>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>End date</Text>
                                                                                        <Input h={"30px"} type="date" name="end_date" value={formData1.end_date}
                                                                                            onChange={(e) => setFormData1({ ...formData1, end_date: e.target.value })}
                                                                                            isRequired
                                                                                        />
                                                                                    </Box>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        {/* <Button w={"80%"} bg={"rgb(24,112,229)"} borderRadius={"10px"} p={"10px"} border={"none"} color={"white"}>Next</Button> */}

                                                    </Box>

                                                    <Box mt={accordionIsOpenf5 ? "30px" : "0px"} mb={accordionIsOpenf5 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf5} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Locations</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select locations to target</Text>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"

                                                                                    value={"all-countries&territories"}
                                                                                    checked={selectedLocationF === "all-countries&territories"}
                                                                                    onChange={() => handleLocationChangeF("all-countries&territories")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired
                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>All countries and territories</label>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <Box >
                                                                                    <input
                                                                                        type="radio"
                                                                                        value="Nepal" // Set the value directly here
                                                                                        checked={selectedLocationF === "Nepal"}
                                                                                        onChange={() => handleLocationChangeF("Nepal")} // Directly set selectedLocation
                                                                                        style={{ width: "16px", height: "16px" }}
                                                                                    // isRequired
                                                                                    />
                                                                                    <label style={{ fontSize: "18px", marginLeft: "5px" }}>Nepal</label>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value={"other"}
                                                                                    checked={selectedLocationF === "other"}
                                                                                    onChange={() => handleLocationChangeF("other")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired
                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>Enter another location</label>
                                                                                {selectedLocationF === "other" && (
                                                                                    <Box ml={"20px"} mt={"20px"}>

                                                                                        <Autocomplete
                                                                                            onLoad={autocomplete => autocompleteRef.current = autocomplete}
                                                                                            onPlaceChanged={handlePlaceSelectF}
                                                                                        >
                                                                                            <Input
                                                                                                h={"40px"}
                                                                                                p={"10px"}
                                                                                                w={"80%"}
                                                                                                fontSize={"18px"}
                                                                                                placeholder="Enter location"
                                                                                                type="text"
                                                                                                onChange={e => setSearchInputF(e.target.value)}
                                                                                                value={searchInputF}
                                                                                            />
                                                                                        </Autocomplete>
                                                                                    </Box>
                                                                                )}
                                                                                {selectedLocationF === "other" && (
                                                                                    <Box>
                                                                                        {selectedLocationsF.length > 0 && (
                                                                                            <Box mt={4}>
                                                                                                <Text fontWeight="bold">Selected Locations:</Text>
                                                                                                <Box mt={"20px"} border={"1px solid #eee"} p={"10px 20px 10px 20px"}>
                                                                                                    {selectedLocationsF.map(location => (
                                                                                                        <Flex key={location.id} backgroundColor={"rgba(60, 64, 67, .04)"} p={"10px"} alignItems="center" justifyContent={"space-between"} mt={"10px"} mb={"10px"}>
                                                                                                            <Text>{location.address}</Text>
                                                                                                            <Button ml={4} size="xs" onClick={() => removeLocationF(location.id)}><CloseIcon></CloseIcon></Button>
                                                                                                        </Flex>
                                                                                                    ))}
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                            </Box>

                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    <Box mt={accordionIsOpenf6 ? "30px" : "0px"} mb={accordionIsOpenf6 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf6} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Languages</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select the languages your customers speak.</Text>
                                                                            <Box mt={"10px"}>
                                                                                <Box
                                                                                    className={`select ${openModal1 ? "open" : ""}`}
                                                                                    onClick={onOpen1}
                                                                                    p={2}
                                                                                    border="1px solid"
                                                                                    borderRadius="md"
                                                                                    cursor="pointer"
                                                                                >
                                                                                    <Text>
                                                                                        {selectedOptionsF.length > 0 ? "Select language" : "Select language"}
                                                                                    </Text>
                                                                                </Box>

                                                                                <Modal isOpen={openModal1} onClose={onModalClose1}>
                                                                                    <ModalOverlay />
                                                                                    <ModalContent>
                                                                                        <ModalHeader>Select the languages</ModalHeader>
                                                                                        <ModalCloseButton />
                                                                                        <ModalBody>
                                                                                            <Box mt={2}>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    placeholder="Search languages..."
                                                                                                    value={searchTermF}
                                                                                                    onChange={handleInputChangeF}
                                                                                                    isRequired
                                                                                                />
                                                                                                <Flex mt={"10px"} direction="column">
                                                                                                    {filteredOptionsF.map((option) => (
                                                                                                        <Checkbox
                                                                                                            key={option}
                                                                                                            isChecked={selectedOptionsF.includes(option)}
                                                                                                            onChange={() => handleOptionSelectF(option)}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </Checkbox>
                                                                                                    ))}
                                                                                                </Flex>
                                                                                            </Box>
                                                                                        </ModalBody>

                                                                                        <ModalFooter>
                                                                                            <Button colorScheme='blue' mr={3} onClick={onModalClose1}>
                                                                                                Close
                                                                                            </Button>

                                                                                        </ModalFooter>
                                                                                    </ModalContent>
                                                                                </Modal>

                                                                                <Box mt={2}>
                                                                                    {
                                                                                        selectedOptions.length > 0 ? <Box>  <Text>Selected Options:</Text>
                                                                                            <Flex gap={"10px"}>
                                                                                                <Wrap mt={2} spacing="10px">
                                                                                                    {selectedOptionsF.map((option) => (
                                                                                                        <WrapItem key={option}>
                                                                                                            <Flex _hover={{ background: "rgba(60, 64, 67, .04)" }} borderRadius={"20px"} mt={"10px"} border={"2px solid #eee"} p={"5px 20px 5px 20px"} key={option} alignItems="center">
                                                                                                                <Text>{option}</Text>
                                                                                                                <CloseIcon
                                                                                                                    ml={2}
                                                                                                                    cursor={"pointer"}
                                                                                                                    style={{ fontSize: "8px" }}
                                                                                                                    size="xs"
                                                                                                                    colorScheme="red"
                                                                                                                    onClick={() => handleRemoveOptionF(option)}
                                                                                                                >
                                                                                                                    Remove
                                                                                                                </CloseIcon>
                                                                                                            </Flex>
                                                                                                        </WrapItem>
                                                                                                    ))}
                                                                                                </Wrap>
                                                                                            </Flex>

                                                                                        </Box>
                                                                                            : null

                                                                                    }

                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    {/* ad scedule */}
                                                    <Box mt={accordionIsOpenf7 ? "30px" : "0px"} mb={accordionIsOpenf7 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf7} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Ad schedule</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box>
                                                                        {boxesF.map((box, index) => (
                                                                            <Flex key={index} mt={index === 0 ? 0 : 4} alignItems="center" gap={"20px"}>
                                                                                {box}{/* Render box without wrapping it in another Box */}
                                                                                {index !== 0 && (
                                                                                    <CloseIcon cursor={"pointer"} colorScheme="red" onClick={() => handleRemoveButtonClickF(index)}>
                                                                                        Remove
                                                                                    </CloseIcon>
                                                                                )}
                                                                            </Flex>
                                                                        ))}
                                                                        <Button mt={4} bg={"rgb(24,112,229)"} w={"15%"} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }} color={"white"} onClick={handleAddButtonClickF}>
                                                                            ADD
                                                                        </Button>
                                                                    </Box>
                                                                    {/* <Box >

                                                    
                                                    <Box>
                                                        <Flex alignItems={"center"} gap={"20px"}>
                                                            <Select>
                                                                <option value="">All Days</option>
                                                                <option value="">Mondays-Fridays</option>
                                                                <option value="">Saturdays-Sundays</option>
                                                                <option value="">Mondays</option>
                                                                <option value="">Tuesdays</option>
                                                                <option value="">Wednesdays</option>
                                                                <option value="">Thrusdays</option>
                                                                <option value="">Fridays</option>
                                                                <option value="">Saturdays</option>
                                                                <option value="">Sundays</option>
                                                            </Select>
                                                            <Input type="time" />
                                                            <Text>to</Text>
                                                            <Input type="time" />
                                                        </Flex>
                                                    </Box>
                                                    <Button mt={"20px"}>ADD</Button>
                                                    </Box> */}



                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>

                                                    <Box mt={accordionIsOpenf1 ? "30px" : "0px"} mb={accordionIsOpenf1 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2 >
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf1} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Demographics</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>
                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Select your demographic targeting
                                                                        </Text>

                                                                        <Box mt={"20px"} >
                                                                            <Flex >
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Gender</Text>

                                                                                    <Box>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Male" defaultChecked={true} id="Male" onChange={() => handleCheckboxChangeF('gender', 'Male')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Male">Male</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="female" defaultChecked={true} id="female" onChange={() => handleCheckboxChangeF('gender', 'Female')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="female">Female</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="unknown" onChange={() => handleCheckboxChangeF('gender', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>



                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Age</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="18-24" defaultChecked={true} id="18-24" onChange={() => handleCheckboxChangeF('age', '18-24')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="18-24">18-24</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="25-34" defaultChecked={true} id="25-34" onChange={() => handleCheckboxChangeF('age', '25-34')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="25-34">25-34</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="35-44" defaultChecked={true} id="35-44" onChange={() => handleCheckboxChangeF('age', '35-44')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="35-44">35-44</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="45-54" defaultChecked={true} id="45-54" onChange={() => handleCheckboxChangeF('age', '45-54')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="45-54">45-54</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="55-64" defaultChecked={true} id="55-64" onChange={() => handleCheckboxChangeF('age', '55-64')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="55-64">55-64</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="65+" defaultChecked={true} id="65+" onChange={() => handleCheckboxChangeF('age', '65+')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="65+">65+</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeF('age', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Parental status</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Not-a-parent" defaultChecked={true} id="Not-a-parent" onChange={() => handleCheckboxChangeF('parental_status', 'Not-a-parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Not-a-parent">Not a parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Parent" defaultChecked={true} id="Parent" onChange={() => handleCheckboxChangeF('parental_status', 'Parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Parent">Parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeF('parental_status', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Household income</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Top10" defaultChecked={true} id="Top10" onChange={() => handleCheckboxChangeF('Householdincome', 'Top10')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Top10">Top 10%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="11-20" defaultChecked={true} id="11-20" onChange={() => handleCheckboxChangeF('Householdincome', '11-20')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="11-20">11-20%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="21-30" defaultChecked={true} id="21-30" onChange={() => handleCheckboxChangeF('Householdincome', '21-30')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="21-30">21-30%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="31-40" defaultChecked={true} id="31-40" onChange={() => handleCheckboxChangeF('Householdincome', '31-40')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="31-40">31-40%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="41-50" defaultChecked={true} id="41-50" onChange={() => handleCheckboxChangeF('Householdincome', '41-50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="41-50">41-50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Lower50" defaultChecked={true} id="Lower50" onChange={() => handleCheckboxChangeF('Householdincome', 'Lower50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Lower50">Lower 50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeF('Householdincome', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box ml={"10px"} mt={"20px"} p={"15px"} bg={"rgb(254,247,224)"} borderRadius={"10px"}>
                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                            <i class="fa-solid fa-circle-info"></i>
                                                                            <Text>Note: Household income targeting is only available in select countries.</Text>
                                                                            <a href={"https://support.google.com/google-ads/answer/2580383?hl=en&_gl=1*rcsn05*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA."} style={{ color: "rgb(24,112,229)", textDecoration: "underline" }}  >Learn more</a>
                                                                        </Flex>
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>


                                                    <Box mt={accordionIsOpenf8 ? "30px" : "0px"} mb={accordionIsOpenf8 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf8} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Keywords</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Choose terms related to your products or services to target relevant content</Text>
                                                                        <Textarea h={"200px"} placeholder="Enter or paste keywords. You can separate each keyword by commas or enter one per line." mt={"20px"} onChange={(e) => handleKeywordsF(e.target.value)} isRequired />
                                                                    </Box>




                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>
                                                    {/* facebook page url create it after the advertise ment type */}
                                                    {/* it will be selceted from the above advertisement type based on it show input if lead ads then image(max5)-compulsory and video url(NC)  or page likes then image(max5) or image ads then image(max5)\
                                                or videoads then video url(min1 and max3) or carousel ads then image(max5) and  video url(min1 ,max3) 
                                                or slideshow ads then image(max5) or collection ads then product-image(max5) or instant experience canvas ads then image(max5)or video url(max3)
                                                or dynamic ads then image(max5) or websiteurl or messenger ads then image(max5) and video-url(max3)
                                                or stories ads videourl(min1) size=1080*1920 or playable ads then app link image(max5) videourl(max3)
                                                or poll ads then ques(max 10) or augmented reality ads(AR) ads then isko delete kar dena 
                                                or sponsored messages isko delete kar dena or event ads image(max5) video-url(max3) 
                                                or offer ads then image(max5) video-url(max3) */}
                                                    <Box mt={accordionIsOpenf9 ? "30px" : "0px"} mb={accordionIsOpenf9 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionf9} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Your facebook video or image</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>

                                                                    {formData1.campaign_subtype === "Lead Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and Video url is optional</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Page-likes" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Image Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Video-Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 3 video URLs.</Text>
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Carousel Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Slideshow Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Collection Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 product images.</Text>
                                                                            {renderImageInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Instant Experience (Canvas) Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images or up to 3 video URLs.</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Dynamic Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Messenger Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Stories Ads" && (
                                                                        <Box>
                                                                            <Text>Add at least 1 video URL with size 1080x1920.</Text>
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Playable Ads" && (
                                                                        <Box>
                                                                            <Text>Add an app link, up to 5 images, and up to 3 video URLs.</Text>
                                                                            <Input
                                                                                mt={"20px"}
                                                                                type="url"
                                                                                placeholder="Enter app link"
                                                                                value={formData1.applink}
                                                                                onChange={(e) => setFormData1({ ...formData1, applink: e.target.value })}
                                                                            />
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Poll Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 10 questions.</Text>
                                                                            {renderQuestions()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Event Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}
                                                                    {formData1.campaign_subtype === "Offer Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInput()}
                                                                            {renderVideoUrlInput()}
                                                                        </Box>
                                                                    )}

                                                                </AccordionPanel>
                                                            </AccordionItem>



                                                        </Accordion>
                                                    </Box>
                                                    <Box mt={"20px"} textAlign={"left"}>
                                                        {
                                                            loadF ? <Button bg={"rgb(24,112,229)"} color={"white"} type="button" _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}><Spinner /></Button> :
                                                                <Button bg={"rgb(24,112,229)"} color={"white"} type="button" onClick={handleSubmitFacebook} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}>Create & Pay</Button>
                                                        }
                                                    </Box>
                                                </form>
                                            )
                                        }
                                        {
                                            activeSocialMedia === "instagram" && (
                                                <form >
                                                    <Box mt={"30px"}>

                                                        {/* <Box w={"30%"} m={"auto"} mt={"30px"} position={"relative"} _hover={{ transition: "transform 0.3s ease-in-out", transform: "scale(1.05)" }} >
                                        <Box borderRadius={"2px"} border={"1px solid blue"} cursor={"pointer"}>
                                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                                <Box w={"50%"} position="relative">
                                                    <Image w={"90%"} src={video} />
                                                </Box>
                                                <Box w={"60%"} textAlign={"left"}>
                                                    <Text fontSize={"16px"} fontWeight={"600"}>Video</Text>
                                                    <Text fontSize={"14px"}>Reach Viewers on YouTube and get conversions</Text>
                                                </Box>
                                                <Box color={"blue"} bg={"white"} zIndex={10} position={"absolute"} top={"-10px"} right={"-5px"} textAlign={"right"}>
                                                    <i class="fa-regular fa-circle-check fa-lg"></i>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box> */}
                                                        <Box mt={accordionIsOpenI2 ? "30px" : "0px"} mb={accordionIsOpenI3 ? "30px" : "0px"}
                                                        >
                                                            <Accordion defaultIndex={[0]} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI3} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Advertisement type</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Box>
                                                                            <Text ml={"10px"} color={"rgb(112,113,115)"}>Select advertisement type
                                                                            </Text>
                                                                            <RadioGroup defaultValue="Lead Ads" value={formDataI.campaign_subtype} onChange={handleFormDataChangeI} isRequired>
                                                                                <Box ml={"10px"}>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Lead Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Lead Ads</Text>
                                                                                                <Text w={"80%"}>Ads designed to collect contact information from users directly within the Facebook platform.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Page-likes'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Page-likes</Text>
                                                                                                <Text w={"80%"}>Get the most reach for your budget using bumper, skippable in-stream, in-feed, and Shorts ads.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10510238?hl=en&_gl=1*1geaso8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#efficient-reach" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Image Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Image Ads</Text>
                                                                                                <Text w={"80%"}>Simple ads that use a single image and can include text, a link, and a call-to-action (CTA) button.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12400225?hl=en&_gl=1*9k1q6f*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' value='Video-Ads' >
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Video-Ads</Text>
                                                                                                <Text w={"80%"}>Ads that use video content. These can appear in the News Feed, Stories, and other placements.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/13982458?hl=en&_gl=1*1amir1j*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Carousel Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Carousel Ads</Text>
                                                                                                <Text w={"80%"}>Ads that allow users to swipe through multiple images or videos within a single ad, each with its own link. <a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/2375497?hl=en&_gl=1*1sxkj8z*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Slideshow Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Slideshow Ads</Text>
                                                                                                <Text w={"80%"}>Lightweight video ads that are created using a series of still images, text, and sound.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10146226?hl=en&_gl=1*tdwqni*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Collection Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Collection Ads</Text>
                                                                                                <Text w={"80%"}>Ads that showcase multiple products and open into a full-screen mobile experience.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/9161595?hl=en&_gl=1*1iubhq8*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Instant Experience (Canvas) Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Instant Experience (Canvas) Ads</Text>
                                                                                                <Text w={"80%"}>Full-screen, interactive ads that load instantly on mobile devices. They can include a mix of images, video, text, and links.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/10227746?hl=en&_gl=1*18uc862*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA.#Video%20campaign%20goals%20and%20subtypes" >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Dynamic Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Dynamic Ads</Text>
                                                                                                <Text w={"80%"}>Ads that automatically show the right products to people who have expressed interest on your website, in your app, or elsewhere on the internet.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Messenger Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Messenger Ads</Text>
                                                                                                <Text w={"80%"}>Ads that appear in the Messenger app. These can start a conversation with your business directly in the Messenger app.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Stories Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Stories Ads</Text>
                                                                                                <Text w={"80%"}>Full-screen ads that appear between user stories on Facebook, Instagram, and Messenger.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Playable Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Playable Ads</Text>
                                                                                                <Text w={"80%"}>Interactive ads that allow users to play a game or interact with the ad content before downloading an app.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Poll Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Poll Ads</Text>
                                                                                                <Text w={"80%"}>Ads that include interactive polls, allowing users to engage with the ad by answering questions.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Event Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Event Ads</Text>
                                                                                                <Text w={"80%"}>Ads that promote an event and can help drive attendance by showing details such as the date, time, and location.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Offer Ads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Offer Ads</Text>
                                                                                                <Text w={"80%"}>Ads that promote a discount or special offer and can include a CTA to redeem the offer.<a style={{ marginLeft: "10px", color: "rgb(24,112,229)", textDecoration: "underline" }} href="https://support.google.com/google-ads/answer/12301500?hl=en&_gl=1*1eahbx1*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA." >Learn more</a></Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenI2 ? "30px" : "0px"} mb={accordionIsOpenI2 ? "30px" : "0px"}
                                                        >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI2} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}> Campaign name</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} >
                                                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                            <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formDataI.campaign_name} fontSize={"18px"} maxLength={50}
                                                                                onChange={(e) => setFormDataI({ ...formDataI, campaign_name: e.target.value })}
                                                                                placeholder="Enter campaign name"
                                                                                isRequired />
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenI4 ? "30px" : "0px"}
                                                            mb={accordionIsOpenI4 ? "30px" : "0px"} >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI4} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Budget and dates</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Text pl={"6px"} mt={"-5px"} color={"rgb(112,113,115)"}>Enter your total budget</Text>
                                                                        <Box p={"10px"}>
                                                                            <Flex justifyContent={"space-between"} alignItems="center">
                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} flex={"1"} p={"10px"}>
                                                                                    <Input
                                                                                        h={"40px"}
                                                                                        p={"10px"}
                                                                                        w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }}
                                                                                        fontSize={"18px"}
                                                                                        name="budget"
                                                                                        value={formDataI.budget}
                                                                                        onChange={handleBudgetChangeI}
                                                                                        placeholder="Enter your total budget"
                                                                                        maxLength={50}
                                                                                        isInvalid={!!errorI} // Apply Chakra's invalid styling
                                                                                    />
                                                                                    {errorI && (
                                                                                        <Text color="red.500" mt={2}>
                                                                                            {errorI}
                                                                                        </Text>
                                                                                    )}
                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                        <Box p={"10px"} >
                                                                            <Box mt={"20px"} p={"10px"} border={"1px solid #eee"}>
                                                                                <Flex justifyContent={"space-between"} gap={"30px"}>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>Start date</Text>
                                                                                        <Input h={"30px"} type="date" name="start_date" value={formDataI.start_date}
                                                                                            onChange={(e) => setFormDataI({ ...formDataI, start_date: e.target.value })}
                                                                                            isRequired />
                                                                                    </Box>
                                                                                    <Box flex={"1"}>
                                                                                        <Text>End date</Text>
                                                                                        <Input h={"30px"} type="date" name="end_date" value={formDataI.end_date}
                                                                                            onChange={(e) => setFormDataI({ ...formDataI, end_date: e.target.value })}
                                                                                            isRequired
                                                                                        />
                                                                                    </Box>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        {/* <Button w={"80%"} bg={"rgb(24,112,229)"} borderRadius={"10px"} p={"10px"} border={"none"} color={"white"}>Next</Button> */}

                                                    </Box>

                                                    <Box mt={accordionIsOpenI5 ? "30px" : "0px"} mb={accordionIsOpenI5 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI5} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Locations</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select locations to target</Text>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"

                                                                                    value={"all-countries&territories"}
                                                                                    checked={selectedLocationI === "all-countries&territories"}
                                                                                    onChange={() => handleLocationChangeI("all-countries&territories")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired
                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>All countries and territories</label>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <Box >
                                                                                    <input
                                                                                        type="radio"
                                                                                        value="Nepal" // Set the value directly here
                                                                                        checked={selectedLocationI === "Nepal"}
                                                                                        onChange={() => handleLocationChangeI("Nepal")} // Directly set selectedLocation
                                                                                        style={{ width: "16px", height: "16px" }}
                                                                                    // isRequired
                                                                                    />
                                                                                    <label style={{ fontSize: "18px", marginLeft: "5px" }}>Nepal</label>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box pl={"10px"} mt={"10px"}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value={"other"}
                                                                                    checked={selectedLocationI === "other"}
                                                                                    onChange={() => handleLocationChangeI("other")}
                                                                                    style={{ width: "16px", height: "16px" }}
                                                                                // isRequired
                                                                                />
                                                                                <label style={{ fontSize: "18px", marginLeft: "5px" }}>Enter another location</label>
                                                                                {selectedLocationI === "other" && (
                                                                                    <Box ml={"20px"} mt={"20px"}>

                                                                                        <Autocomplete
                                                                                            onLoad={autocomplete => autocompleteRef.current = autocomplete}
                                                                                            onPlaceChanged={handlePlaceSelectI}
                                                                                        >
                                                                                            <Input
                                                                                                h={"40px"}
                                                                                                p={"10px"}
                                                                                                w={"80%"}
                                                                                                fontSize={"18px"}
                                                                                                placeholder="Enter location"
                                                                                                type="text"
                                                                                                onChange={e => setSearchInputI(e.target.value)}
                                                                                                value={searchInputI}
                                                                                            />
                                                                                        </Autocomplete>
                                                                                    </Box>
                                                                                )}
                                                                                {selectedLocationI === "other" && (
                                                                                    <Box>
                                                                                        {selectedLocationsI.length > 0 && (
                                                                                            <Box mt={4}>
                                                                                                <Text fontWeight="bold">Selected Locations:</Text>
                                                                                                <Box mt={"20px"} border={"1px solid #eee"} p={"10px 20px 10px 20px"}>
                                                                                                    {selectedLocationsI.map(location => (
                                                                                                        <Flex key={location.id} backgroundColor={"rgba(60, 64, 67, .04)"} p={"10px"} alignItems="center" justifyContent={"space-between"} mt={"10px"} mb={"10px"}>
                                                                                                            <Text>{location.address}</Text>
                                                                                                            <Button ml={4} size="xs" onClick={() => removeLocationI(location.id)}><CloseIcon></CloseIcon></Button>
                                                                                                        </Flex>
                                                                                                    ))}
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                            </Box>

                                                                        </Box>

                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    <Box mt={accordionIsOpenI6 ? "30px" : "0px"} mb={accordionIsOpenI6 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI6} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Languages</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} textAlign={"left"}>

                                                                        <Box pl={"10px"}  >
                                                                            <Text color={"rgb(112,113,115)"}>Select the languages your customers speak.</Text>
                                                                            <Box mt={"10px"}>
                                                                                <Box
                                                                                    className={`select ${openModal1 ? "open" : ""}`}
                                                                                    onClick={onOpen1}
                                                                                    p={2}
                                                                                    border="1px solid"
                                                                                    borderRadius="md"
                                                                                    cursor="pointer"
                                                                                >
                                                                                    <Text>
                                                                                        {selectedOptionsI.length > 0 ? "Select language" : "Select language"}
                                                                                    </Text>
                                                                                </Box>

                                                                                <Modal isOpen={openModal1} onClose={onModalClose1}>
                                                                                    <ModalOverlay />
                                                                                    <ModalContent>
                                                                                        <ModalHeader>Select the languages</ModalHeader>
                                                                                        <ModalCloseButton />
                                                                                        <ModalBody>
                                                                                            <Box mt={2}>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    placeholder="Search languages..."
                                                                                                    value={searchTermI}
                                                                                                    onChange={handleInputChangeI}
                                                                                                    isRequired
                                                                                                />
                                                                                                <Flex mt={"10px"} direction="column">
                                                                                                    {filteredOptionsI.map((option) => (
                                                                                                        <Checkbox
                                                                                                            key={option}
                                                                                                            isChecked={selectedOptionsI.includes(option)}
                                                                                                            onChange={() => handleOptionSelectI(option)}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </Checkbox>
                                                                                                    ))}
                                                                                                </Flex>
                                                                                            </Box>
                                                                                        </ModalBody>

                                                                                        <ModalFooter>
                                                                                            <Button colorScheme='blue' mr={3} onClick={onModalClose1}>
                                                                                                Close
                                                                                            </Button>

                                                                                        </ModalFooter>
                                                                                    </ModalContent>
                                                                                </Modal>

                                                                                <Box mt={2}>
                                                                                    {
                                                                                        selectedOptionsI.length > 0 ? <Box>  <Text>Selected Options:</Text>
                                                                                            <Flex gap={"10px"}>
                                                                                                <Wrap mt={2} spacing="10px">
                                                                                                    {selectedOptionsI.map((option) => (
                                                                                                        <WrapItem key={option}>
                                                                                                            <Flex _hover={{ background: "rgba(60, 64, 67, .04)" }} borderRadius={"20px"} mt={"10px"} border={"2px solid #eee"} p={"5px 20px 5px 20px"} key={option} alignItems="center">
                                                                                                                <Text>{option}</Text>
                                                                                                                <CloseIcon
                                                                                                                    ml={2}
                                                                                                                    cursor={"pointer"}
                                                                                                                    style={{ fontSize: "8px" }}
                                                                                                                    size="xs"
                                                                                                                    colorScheme="red"
                                                                                                                    onClick={() => handleRemoveOptionI(option)}
                                                                                                                >
                                                                                                                    Remove
                                                                                                                </CloseIcon>
                                                                                                            </Flex>
                                                                                                        </WrapItem>
                                                                                                    ))}
                                                                                                </Wrap>
                                                                                            </Flex>

                                                                                        </Box>
                                                                                            : null

                                                                                    }

                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>

                                                    </Box>

                                                    {/* ad scedule */}
                                                    <Box mt={accordionIsOpenI7 ? "30px" : "0px"} mb={accordionIsOpenI7 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI7} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Ad schedule</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box>
                                                                        {boxesI.map((box, index) => (
                                                                            <Flex key={index} mt={index === 0 ? 0 : 4} alignItems="center" gap={"20px"}>
                                                                                {box}{/* Render box without wrapping it in another Box */}
                                                                                {index !== 0 && (
                                                                                    <CloseIcon cursor={"pointer"} colorScheme="red" onClick={() => handleRemoveButtonClickI(index)}>
                                                                                        Remove
                                                                                    </CloseIcon>
                                                                                )}
                                                                            </Flex>
                                                                        ))}
                                                                        <Button mt={4} bg={"rgb(24,112,229)"} w={"15%"} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }} color={"white"} onClick={handleAddButtonClickI}>
                                                                            ADD
                                                                        </Button>
                                                                    </Box>
                                                                    {/* <Box >

                                                
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"20px"}>
                                                        <Select>
                                                            <option value="">All Days</option>
                                                            <option value="">Mondays-Fridays</option>
                                                            <option value="">Saturdays-Sundays</option>
                                                            <option value="">Mondays</option>
                                                            <option value="">Tuesdays</option>
                                                            <option value="">Wednesdays</option>
                                                            <option value="">Thrusdays</option>
                                                            <option value="">Fridays</option>
                                                            <option value="">Saturdays</option>
                                                            <option value="">Sundays</option>
                                                        </Select>
                                                        <Input type="time" />
                                                        <Text>to</Text>
                                                        <Input type="time" />
                                                    </Flex>
                                                </Box>
                                                <Button mt={"20px"}>ADD</Button>
                                                </Box> */}



                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>

                                                    <Box mt={accordionIsOpenI1 ? "30px" : "0px"} mb={accordionIsOpenI1 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2 >
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI1} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Demographics</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>
                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Select your demographic targeting
                                                                        </Text>

                                                                        <Box mt={"20px"} >
                                                                            <Flex >
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Gender</Text>

                                                                                    <Box>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Male" defaultChecked={true} id="Male" onChange={() => handleCheckboxChangeI('gender', 'Male')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Male">Male</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="female" defaultChecked={true} id="female" onChange={() => handleCheckboxChangeI('gender', 'Female')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="female">Female</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="unknown" onChange={() => handleCheckboxChangeI('gender', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>



                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Age</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="18-24" defaultChecked={true} id="18-24" onChange={() => handleCheckboxChangeI('age', '18-24')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="18-24">18-24</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="25-34" defaultChecked={true} id="25-34" onChange={() => handleCheckboxChangeI('age', '25-34')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="25-34">25-34</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="35-44" defaultChecked={true} id="35-44" onChange={() => handleCheckboxChangeI('age', '35-44')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="35-44">35-44</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="45-54" defaultChecked={true} id="45-54" onChange={() => handleCheckboxChangeI('age', '45-54')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="45-54">45-54</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="55-64" defaultChecked={true} id="55-64" onChange={() => handleCheckboxChangeI('age', '55-64')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="55-64">55-64</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="65+" defaultChecked={true} id="65+" onChange={() => handleCheckboxChangeI('age', '65+')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="65+">65+</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeI('age', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>


                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Parental status</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Not-a-parent" defaultChecked={true} id="Not-a-parent" onChange={() => handleCheckboxChangeI('parental_status', 'Not-a-parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Not-a-parent">Not a parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Parent" defaultChecked={true} id="Parent" onChange={() => handleCheckboxChangeI('parental_status', 'Parent')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Parent">Parent</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeI('parental_status', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                                <Box w={"25%"} border={"1px solid #eee"} p={"15px"}>
                                                                                    <Text pb={"15px"}>Household income</Text>

                                                                                    <Box >
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Top10" defaultChecked={true} id="Top10" onChange={() => handleCheckboxChangeI('Householdincome', 'Top10')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Top10">Top 10%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="11-20" defaultChecked={true} id="11-20" onChange={() => handleCheckboxChangeI('Householdincome', '11-20')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="11-20">11-20%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="21-30" defaultChecked={true} id="21-30" onChange={() => handleCheckboxChangeI('Householdincome', '21-30')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="21-30">21-30%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="31-40" defaultChecked={true} id="31-40" onChange={() => handleCheckboxChangeI('Householdincome', '31-40')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="31-40">31-40%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="41-50" defaultChecked={true} id="41-50" onChange={() => handleCheckboxChangeI('Householdincome', '41-50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="41-50">41-50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Lower50" defaultChecked={true} id="Lower50" onChange={() => handleCheckboxChangeI('Householdincome', 'Lower50')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Lower50">Lower 50%</label>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"10px"}>
                                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                                            <input type="checkbox" style={{ width: "18px", height: "18px" }} value="Unknown" defaultChecked={true} id="Unknown" onChange={() => handleCheckboxChangeI('Householdincome', 'Unknown')} />
                                                                                            <label style={{ fontSize: "16px" }} htmlFor="Unknown">Unknown</label>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box ml={"10px"} mt={"20px"} p={"15px"} bg={"rgb(254,247,224)"} borderRadius={"10px"}>
                                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                                            <i class="fa-solid fa-circle-info"></i>
                                                                            <Text>Note: Household income targeting is only available in select countries.</Text>
                                                                            <a href={"https://support.google.com/google-ads/answer/2580383?hl=en&_gl=1*rcsn05*_ga*MzA4NDQxMTkyLjE3MTE5NjIzMzI.*_ga_V9K47ZG8NP*MTcxMjI0NjIwNS43LjEuMTcxMjI0NjIwNS42MC4wLjA."} style={{ color: "rgb(24,112,229)", textDecoration: "underline" }}  >Learn more</a>
                                                                        </Flex>
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>


                                                    <Box mt={accordionIsOpenI8 ? "30px" : "0px"} mb={accordionIsOpenI8 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI8} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Keywords</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>


                                                                    <Box ml={"10px"}>
                                                                        <Text color={"rgb(112,113,115)"}>Choose terms related to your products or services to target relevant content</Text>
                                                                        <Textarea h={"200px"} placeholder="Enter or paste keywords. You can separate each keyword by commas or enter one per line." mt={"20px"} onChange={(e) => handleKeywordsI(e.target.value)} isRequired />
                                                                    </Box>




                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>

                                                    <Box mt={accordionIsOpenI9 ? "30px" : "0px"} mb={accordionIsOpenI9 ? "30px" : "0px"}>
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionI9} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Your Instagram video or image</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={8} textAlign={"left"}>

                                                                    {formDataI.campaign_subtype === "Lead Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and Video url is optional</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Page-likes" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Image Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Video-Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 3 video URLs.</Text>
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Carousel Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Slideshow Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Collection Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 product images.</Text>
                                                                            {renderImageInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Instant Experience (Canvas) Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images or up to 3 video URLs.</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Dynamic Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images.</Text>
                                                                            {renderImageInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Messenger Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Stories Ads" && (
                                                                        <Box>
                                                                            <Text>Add at least 1 video URL with size 1080x1920.</Text>
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Playable Ads" && (
                                                                        <Box>
                                                                            <Text>Add an app link, up to 5 images, and up to 3 video URLs.</Text>
                                                                            <Input
                                                                                mt={"20px"}
                                                                                type="url"
                                                                                placeholder="Enter app link"
                                                                                value={formDataI.applink}
                                                                                onChange={(e) => setFormDataI({ ...formDataI, applink: e.target.value })}
                                                                            />
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Poll Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 10 questions. (One question per input)</Text>
                                                                            {renderQuestionsI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Event Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}
                                                                    {formDataI.campaign_subtype === "Offer Ads" && (
                                                                        <Box>
                                                                            <Text>Add up to 5 images and up to 3 video URLs.</Text>
                                                                            {renderImageInputI()}
                                                                            {renderVideoUrlInputI()}
                                                                        </Box>
                                                                    )}

                                                                </AccordionPanel>
                                                            </AccordionItem>



                                                        </Accordion>
                                                    </Box>
                                                    {/* facebook page url create it after the advertise ment type */}
                                                    {/* it will be se;ceted from the above advertisement type based on it show input if lead ads then image(max5)-compulsory and video url(NC)  or page likes then image(max5) or image ads then image(max5)\
                                            or videoads then video url(min1 and max3) or carousel ads then image(max5) and  video url(min1 ,max3) 
                                            or slideshow ads then image(max5) or collection ads then product-image(max5) or instant experience canvas ads then image(max5)or video url(max3)
                                            or dynamic ads then image(max5) or websiteurl or messenger ads then image(max5) and video-url(max3)
                                            or stories ads videourl(min1) size=1080*1920 or playable ads then app link image(max5) videourl(max3)
                                            or poll ads then ques(max 10) or augmented reality ads(AR) ads then isko delete kar dena 
                                            or sponsored messages isko delete kar dena or event ads image(max5) video-url(max3) 
                                            or offer ads then image(max5) video-url(max3) */}
                                                    {/* <Box mt={accordionIsOpen9 ? "30px" : "0px"} mb={accordionIsOpen9 ? "30px" : "0px"}>
                                                <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                    <AccordionItem borderWidth={0}>
                                                        <h2>
                                                            <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordion9} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                <Box flex='1' textAlign='left'  >
                                                                    <Text fontSize={"16px"}>Your YouTube Video</Text>
                                                                </Box>

                                                                <AccordionIcon fontSize={"25px"} />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={8} textAlign={"left"}>
                                                            <Text>Add up to 5 videos. Improve your campaign performance by using videos with different orientations.</Text>
                                                            <Box mt={"20px"}>
                                                                <Input name="search" display={selectedVideos.length === 5 ? "none" : "block"} value={search} onChange={handleChangeYoutube} onInput={() => debounce(main)} />
                                                            </Box>
                                                            <Box boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" borderRadius={"10px"} style={{ scrollbarColor: '#ccc #eee', scrollbarWidth: "thin" }} maxWidth={"100%"} overflowY={"auto"} maxHeight={"300px"}>
                                                                {
                                                                    selectedVideos.length === 5 ? null :
                                                                        (
                                                                            youtube.map((ele, idx) => {
                                                                                return (
                                                                                    <Box onClick={() => handleVideo(ele)} p={"20px"} borderBottom={"1px solid #eee"} h={"13vh"} key={idx} _hover={{ background: "rgba(60, 64, 67, .04)" }}>
                                                                                        <Flex gap={"20px"} alignItems={"center"}>
                                                                                            <Image w={"10%"} src={ele.snippet.thumbnails.high.url} />
                                                                                            <Box>
                                                                                                <Text fontWeight={"bold"}>{ele.snippet.title}</Text>
                                                                                                <Text fontSize={"12px"}>{ele.snippet.description}</Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                );
                                                                            })
                                                                        )
                                                                }
                                                            </Box>


                                                            <Box mt={"20px"}>
                                                                {selectedVideos.length <= 5 && (

                                                                    selectedVideos.map((ele, idx) => {
                                                                        return (
                                                                            <Box p={"20px"} onClick={() => handleSelectedChange(ele)} borderBottom={"1px solid #eee"} h={"13vh"} key={idx} _hover={{ background: "rgba(60, 64, 67, .04)" }}>

                                                                                <Flex gap={"20px"} alignItems={"center"}>
                                                                                    <Image w={"10%"} src={ele.snippet.thumbnails.high.url} />
                                                                                    <Box>
                                                                                        <Text fontWeight={"bold"}>{ele.snippet.title}</Text>
                                                                                        <Text fontSize={"12px"}>{ele.snippet.description}</Text>
                                                                                    </Box>
                                                                                </Flex>


                                                                            </Box>
                                                                        )
                                                                    })

                                                                )}
                                                            </Box>
                                                        </AccordionPanel>
                                                    </AccordionItem>



                                                </Accordion>
                                            </Box> */}
                                                    <Box mt={"20px"} textAlign={"left"}>
                                                        {
                                                            loadI ? <Button bg={"rgb(24,112,229)"} color={"white"} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}><Spinner /></Button> : <Button bg={"rgb(24,112,229)"} color={"white"} onClick={handleSubmitInstagram} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}>Create & Pay</Button>
                                                        }
                                                    </Box>
                                                </form>
                                            )
                                        }
                                        {
                                            activeSocialMedia === "google" && (
                                                <form >
                                                    <Box mt={"30px"}>

                                                        {/* <Box w={"30%"} m={"auto"} mt={"30px"} position={"relative"} _hover={{ transition: "transform 0.3s ease-in-out", transform: "scale(1.05)" }} >
                                            <Box borderRadius={"2px"} border={"1px solid blue"} cursor={"pointer"}>
                                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box w={"50%"} position="relative">
                                                        <Image w={"90%"} src={video} />
                                                    </Box>
                                                    <Box w={"60%"} textAlign={"left"}>
                                                        <Text fontSize={"16px"} fontWeight={"600"}>Video</Text>
                                                        <Text fontSize={"14px"}>Reach Viewers on YouTube and get conversions</Text>
                                                    </Box>
                                                    <Box color={"blue"} bg={"white"} zIndex={10} position={"absolute"} top={"-10px"} right={"-5px"} textAlign={"right"}>
                                                        <i class="fa-regular fa-circle-check fa-lg"></i>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box> */}
                                                        <Box mt={accordionIsOpenG2 ? "30px" : "0px"} mb={accordionIsOpenG3 ? "30px" : "0px"}
                                                        >
                                                            <Accordion defaultIndex={[0]} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG3} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Advertisement type</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={8} textAlign={"left"}>
                                                                        <Box>
                                                                            <Text ml={"10px"} color={"rgb(112,113,115)"}>Select advertisement type
                                                                            </Text>
                                                                            <RadioGroup defaultValue="Lead Ads" value={formDataG.campaign_subtype} onChange={handleFormDataChangeG} isRequired>
                                                                                <Box ml={"10px"}>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Sales'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Sales</Text>
                                                                                                <Text w={"80%"}>Drive sales online, in app, by phone, or in store</Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Leads'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Leads
                                                                                                </Text>
                                                                                                <Text w={"80%"}>Get leads and other conversions by encouraging customers to take action</Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' value='Website traffic' >
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Website traffic</Text>
                                                                                                <Text w={"80%"}>Get the right people to visit your website</Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='App promotion'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>App promotion</Text>
                                                                                                <Text w={"80%"}>Get more installs, engagement and pre-registration for your app</Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>

                                                                                    <Box mt={"30px"}>
                                                                                        <Flex gap={"10px"} alignItems={"center"}>
                                                                                            <Box>
                                                                                                <Radio size='lg' colorScheme='blue' name="campaign_subtype" value='Local store visits and promotions'>
                                                                                                </Radio>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text>Local store visits and promotions</Text>
                                                                                                <Text w={"80%"}>Drive visits to local stores, including restaurants and dealerships.
                                                                                                </Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenG2 ? "30px" : "0px"} mb={accordionIsOpenG2 ? "30px" : "0px"}
                                                        >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG2} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Title</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} >
                                                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                            <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formDataG.title} fontSize={"18px"} maxLength={50}
                                                                                onChange={(e) => setFormDataG({ ...formDataG, title: e.target.value })}
                                                                                placeholder="Enter Title"
                                                                                isRequired />
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        <Box mt={accordionIsOpenG4 ? "30px" : "0px"}
                                                            mb={accordionIsOpenG4 ? "30px" : "0px"} >
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                <AccordionItem borderWidth={0}>
                                                                    <h2>
                                                                        <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG4} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                            <Box flex='1' textAlign='left'  >
                                                                                <Text fontSize={"16px"}>Description</Text>
                                                                            </Box>

                                                                            <AccordionIcon fontSize={"25px"} />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={17} >
                                                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                            <Textarea h={"40px"} p={"10px"} w={"100%"} name="description" value={formDataG.description} fontSize={"18px"}
                                                                                onChange={(e) => setFormDataG({ ...formDataG, description: e.target.value })}
                                                                                placeholder="Enter description"
                                                                                isRequired />
                                                                        </Box>
                                                                    </AccordionPanel>
                                                                </AccordionItem>


                                                            </Accordion>
                                                        </Box>
                                                        {/* <Button w={"80%"} bg={"rgb(24,112,229)"} borderRadius={"10px"} p={"10px"} border={"none"} color={"white"}>Next</Button> */}

                                                    </Box>


                                                    <Box mt={accordionIsOpenG9 ? "30px" : "0px"}
                                                        mb={accordionIsOpenG9 ? "30px" : "0px"} >
                                                        <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                            <AccordionItem borderWidth={0}>
                                                                <h2>
                                                                    <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG4} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                        <Box flex='1' textAlign='left'  >
                                                                            <Text fontSize={"16px"}>Budget</Text>
                                                                        </Box>

                                                                        <AccordionIcon fontSize={"25px"} />
                                                                    </AccordionButton>
                                                                </h2>
                                                                <AccordionPanel pb={17} >
                                                                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} flex={"1"} p={"10px"}>
                                                                        <Input
                                                                            h={"40px"}
                                                                            p={"10px"}
                                                                            w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }}
                                                                            fontSize={"18px"}
                                                                            name="budget"
                                                                            value={formDataG.budget}
                                                                            onChange={handleBudgetChangeG}
                                                                            placeholder="Enter your total budget"
                                                                            maxLength={50}
                                                                            isInvalid={!!errorG} // Apply Chakra's invalid styling
                                                                        />
                                                                        {errorG && (
                                                                            <Text color="red.500" mt={2}>
                                                                                {errorG}
                                                                            </Text>
                                                                        )}
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>


                                                        </Accordion>
                                                    </Box>

                                                    <Box mt={accordionIsOpenG5 ? "30px" : "0px"} mb={accordionIsOpenG5 ? "30px" : "0px"}>

                                                        <Box>
                                                            <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                {
                                                                    formDataG.campaign_subtype === "App promotion" ?
                                                                        <AccordionItem borderWidth={0}>
                                                                            <h2>
                                                                                <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG5} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                                    <Box flex='1' textAlign='left'  >
                                                                                        <Text fontSize={"16px"}>App Link</Text>
                                                                                    </Box>

                                                                                    <AccordionIcon fontSize={"25px"} />
                                                                                </AccordionButton>
                                                                            </h2>
                                                                            <AccordionPanel pb={17} textAlign={"left"}>

                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                                    <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formDataG.weblink} fontSize={"18px"} maxLength={50}
                                                                                        onChange={(e) => setFormDataG({ ...formDataG, weblink: e.target.value })}
                                                                                        placeholder="Enter Website link"
                                                                                        isRequired />
                                                                                </Box>

                                                                            </AccordionPanel>
                                                                        </AccordionItem> :
                                                                        <AccordionItem borderWidth={0}>
                                                                            <h2>
                                                                                <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG5} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                                    <Box flex='1' textAlign='left'  >
                                                                                        <Text fontSize={"16px"}>Website Link</Text>
                                                                                    </Box>

                                                                                    <AccordionIcon fontSize={"25px"} />
                                                                                </AccordionButton>
                                                                            </h2>
                                                                            <AccordionPanel pb={17} textAlign={"left"}>

                                                                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                                    <Input h={"40px"} p={"10px"} w={"100%"} name="campaign_name" value={formDataG.weblink} fontSize={"18px"} maxLength={50}
                                                                                        onChange={(e) => setFormDataG({ ...formDataG, weblink: e.target.value })}
                                                                                        placeholder="Enter Website link"
                                                                                        isRequired />
                                                                                </Box>

                                                                            </AccordionPanel>
                                                                        </AccordionItem>

                                                                }


                                                            </Accordion>
                                                        </Box>

                                                    </Box>



                                                    {
                                                        formDataG.campaign_subtype === "Local store visits and promotions" ? <Box mt={accordionIsOpenG7 ? "30px" : "0px"} mb={accordionIsOpenG7 ? "30px" : "0px"}>

                                                            <Box>
                                                                <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                    <AccordionItem borderWidth={0}>
                                                                        <h2>
                                                                            <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG7} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                                <Box flex='1' textAlign='left'  >
                                                                                    <Text fontSize={"16px"}>Store Location</Text>
                                                                                </Box>

                                                                                <AccordionIcon fontSize={"25px"} />
                                                                            </AccordionButton>
                                                                        </h2>
                                                                        <AccordionPanel pb={17} textAlign={"left"}>

                                                                            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} pl={"10px"}  >
                                                                                <Input h={"40px"} p={"10px"} w={"100%"} name="store_location" value={formDataG.store_location} fontSize={"18px"} maxLength={50}
                                                                                    onChange={(e) => setFormDataG({ ...formDataG, store_location: e.target.value })}
                                                                                    placeholder="Enter store location"
                                                                                    isRequired />
                                                                            </Box>


                                                                        </AccordionPanel>
                                                                    </AccordionItem>


                                                                </Accordion>
                                                            </Box>

                                                        </Box> : null
                                                    }

                                                    {
                                                        formDataG.campaign_subtype === "Local store visits and promotions" ?
                                                            <Box mt={accordionIsOpenG8 ? "30px" : "0px"} mb={accordionIsOpenG8 ? "30px" : "0px"}>

                                                                <Box>
                                                                    <Accordion boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" allowMultiple borderWidth={0}>
                                                                        <AccordionItem borderWidth={0}>
                                                                            <h2>
                                                                                <AccordionButton pl={"20px"} pr={"20px"} bg={"white"} _hover={{ background: "rgba(60, 64, 67, .04)" }} onClick={toggleAccordionG8} boxShadow="rgba(99, 99, 99, 0.2) 0px 0px 0px 0px;" borderWidth={0}>
                                                                                    <Box flex='1' textAlign='left'  >
                                                                                        <Text fontSize={"16px"}>Google Business Profile URL <span style={{ color: "#C3C4C4" }}>(Optional)</span></Text>
                                                                                    </Box>

                                                                                    <AccordionIcon fontSize={"25px"} />
                                                                                </AccordionButton>
                                                                            </h2>
                                                                            <AccordionPanel pb={17} textAlign={"left"}>

                                                                                <Box w={"60%"} pl={"10px"}  >
                                                                                    <Input h={"40px"} p={"10px"} w={"100%"} name="profile_url" value={formDataG.profile_url} fontSize={"18px"} maxLength={50}
                                                                                        onChange={(e) => setFormDataG({ ...formDataG, profile_url: e.target.value })}
                                                                                        placeholder="Enter Google Business Profile URL"
                                                                                        isRequired />
                                                                                </Box>


                                                                            </AccordionPanel>
                                                                        </AccordionItem>


                                                                    </Accordion>
                                                                </Box>

                                                            </Box> : null
                                                    }

                                                    <Box mt={"20px"} textAlign={"left"}>
                                                        {
                                                            loadG ? <Button bg={"rgb(24,112,229)"} color={"white"} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}><Spinner /></Button> : <Button bg={"rgb(24,112,229)"} color={"white"} onClick={handleSubmitGoogle} _hover={{ border: "1px solid rgb(24,112,229)", bg: "white", color: "black" }}>Create & Pay</Button>
                                                        }
                                                    </Box>
                                                </form>
                                            )
                                        }
                                    </Box>


                                </Box>
                                <Box mt={"30px"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: "0px", sm: "0px", md: "0px", lg: "20px", xl: "20px" }} boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;" maxHeight="100vh">
                                    <Box p={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }}>
                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Video-Views" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                    <div className="performance-metrics">
                                                        <div className="metric">
                                                            <h3>Views</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.96))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.16))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.010))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0027))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPV</h3>
                                                            <p>NPR 0.16 – NPR 0.96</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Budget spend</h3>
                                                            <p>95% – 100%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Efficient-reach" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                    <div className="performance-metrics">

                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.036))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.012))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPM</h3>
                                                            <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.016)))} – NPR {formatNumberWithSuffix(Math.floor(formData.budget * 0.061))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Budget spend</h3>
                                                            <p>95% – 100%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null

                                        }
                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Target-frequency" && formData.budget !== "" ?
                                                (<div className="campaign-estimates">
                                                    <div className="info-box">
                                                        <i className="info-icon">i</i>
                                                        <p>Estimates aren’t currently available for target frequency campaigns</p>
                                                    </div>
                                                </div>
                                                ) : null

                                        }
                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Non-skippable-reach" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                    <div className="performance-metrics">

                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.23))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.061))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPM</h3>
                                                            <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.066) * 2))} – NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Budget spend</h3>
                                                            <p>95% – 100%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Drive-conversions" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                    <div className="performance-metrics">
                                                        <div className="metric">
                                                            <h3>Conversions</h3>
                                                            <p>Unavailable</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.0045))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0018))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPA</h3>
                                                            <p>Unavailable</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Ad-sequence" && formData.budget !== "" ?
                                                (<div className="campaign-estimates">
                                                    <div className="info-box">
                                                        <i className="info-icon">i</i>
                                                        <p>Estimates aren’t currently available for Ad-sequence campaigns</p>
                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "Audio" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                    <div className="performance-metrics">

                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.073))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.015))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPM</h3>
                                                            <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.013) * 2))} – NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.12) * 2))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Budget spend</h3>
                                                            <p>95% – 100%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null

                                        }
                                        {
                                            activeSocialMedia === "youtube" && formData.campaign_subtype === "YouTube-engagements" && formData.budget !== "" ?
                                                (<div className="performance-card">
                                                    <h2>Your estimated performance</h2>
                                                    <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                    <div className="performance-metrics">
                                                        <div className="metric">
                                                            <h3>Conversions</h3>
                                                            <p>Unavailable</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Impressions</h3>
                                                            <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.0073))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0028))}</p>
                                                        </div>
                                                        <div className="metric">
                                                            <h3>Average CPA</h3>
                                                            <p>Unavailable</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "facebook" && (formData1.campaign_subtype === "Lead Ads" || formData1.campaign_subtype === "Page-likes" || formData1.campaign_subtype === "Image Ads" || formData1.campaign_subtype === "Video-Ads" || formData1.campaign_subtype === "Carousel Ads" ||
                                                formData1.campaign_subtype === "Slideshow Ads"
                                                || formData1.campaign_subtype === "Collection Ads" || formData1.campaign_subtype === "Instant Experience (Canvas) Ads" ||
                                                formData1.campaign_subtype === "Dynamic Ads" || formData1.campaign_subtype === "Messenger Ads"
                                                || formData1.campaign_subtype === "Stories Ads" || formData1.campaign_subtype === "Playable Ads"
                                                || formData1.campaign_subtype === "Poll Ads" || formData1.campaign_subtype === "Event Ads" || formData1.campaign_subtype === "Offer Ads") && formData1.budget !== "" ?
                                                (<div className="campaign-estimates">
                                                    <div className="info-box">
                                                        <i className="info-icon">i</i>
                                                        <p>Estimates aren’t currently available for this type of campaigns</p>
                                                    </div>
                                                </div>
                                                ) : null

                                        }

                                        {
                                            activeSocialMedia === "instagram" && (formDataI.campaign_subtype === "Lead Ads" || formDataI.campaign_subtype === "Page-likes" || formDataI.campaign_subtype === "Image Ads" || formDataI.campaign_subtype === "Video-Ads" || formDataI.campaign_subtype === "Carousel Ads" ||
                                                formDataI.campaign_subtype === "Slideshow Ads"
                                                || formDataI.campaign_subtype === "Collection Ads" || formDataI.campaign_subtype === "Instant Experience (Canvas) Ads" ||
                                                formDataI.campaign_subtype === "Dynamic Ads" || formDataI.campaign_subtype === "Messenger Ads"
                                                || formDataI.campaign_subtype === "Stories Ads" || formDataI.campaign_subtype === "Playable Ads"
                                                || formDataI.campaign_subtype === "Poll Ads" || formDataI.campaign_subtype === "Event Ads" || formDataI.campaign_subtype === "Offer Ads") && formDataI.budget !== "" ?
                                                (<div className="campaign-estimates">
                                                    <div className="info-box">
                                                        <i className="info-icon">i</i>
                                                        <p>Estimates aren’t currently available for this type of campaigns</p>
                                                    </div>
                                                </div>
                                                ) : null

                                        }
                                        {
                                            activeSocialMedia === "google" && (formDataG.campaign_subtype === "Sales" || formDataG.campaign_subtype === "Page-likes" || formDataG.campaign_subtype === "Leads" || formDataG.campaign_subtype === "Website traffic" || formDataG.campaign_subtype === "App promotion" ||
                                                formDataG.campaign_subtype === "Local store visits and promotions"
                                            ) && formDataG.budget !== "" ?
                                                (<div className="campaign-estimates">
                                                    <div className="info-box">
                                                        <i className="info-icon">i</i>
                                                        <p>Estimates aren’t currently available for this type of campaigns</p>
                                                    </div>
                                                </div>
                                                ) : null

                                        }


                                    </Box>
                                </Box>
                            </Box>
                            <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={"20px"} boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;" maxHeight="100vh">
                                <Box p={"10px"}>
                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Video-Views" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                <div className="performance-metrics">
                                                    <div className="metric">
                                                        <h3>Views</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.96))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.16))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.010))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0027))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPV</h3>
                                                        <p>NPR 0.16 – NPR 0.96</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Budget spend</h3>
                                                        <p>95% – 100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Efficient-reach" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                <div className="performance-metrics">

                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.036))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.012))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPM</h3>
                                                        <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.016)))} – NPR {formatNumberWithSuffix(Math.floor(formData.budget * 0.061))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Budget spend</h3>
                                                        <p>95% – 100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null

                                    }
                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Target-frequency" && formData.budget !== "" ?
                                            (<div className="campaign-estimates">
                                                <div className="info-box">
                                                    <i className="info-icon">i</i>
                                                    <p>Estimates aren’t currently available for target frequency campaigns</p>
                                                </div>
                                            </div>
                                            ) : null

                                    }
                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Non-skippable-reach" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                <div className="performance-metrics">

                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.23))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.061))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPM</h3>
                                                        <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.066) * 2))} – NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Budget spend</h3>
                                                        <p>95% – 100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Drive-conversions" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                <div className="performance-metrics">
                                                    <div className="metric">
                                                        <h3>Conversions</h3>
                                                        <p>Unavailable</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.0045))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0018))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPA</h3>
                                                        <p>Unavailable</p>
                                                    </div>

                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Ad-sequence" && formData.budget !== "" ?
                                            (<div className="campaign-estimates">
                                                <div className="info-box">
                                                    <i className="info-icon">i</i>
                                                    <p>Estimates aren’t currently available for Ad-sequence campaigns</p>
                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "Audio" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.26) * 2))}</p>
                                                <div className="performance-metrics">

                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.073))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.015))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPM</h3>
                                                        <p>NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.013) * 2))} – NPR {formatNumberWithSuffix(Math.floor((formData.budget * 0.12) * 2))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Budget spend</h3>
                                                        <p>95% – 100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null

                                    }
                                    {
                                        activeSocialMedia === "youtube" && formData.campaign_subtype === "YouTube-engagements" && formData.budget !== "" ?
                                            (<div className="performance-card">
                                                <h2>Your estimated performance</h2>
                                                <p>Based on your targeting, settings, total campaign budget of NPR {formatNumberWithSuffix(Math.floor(formData.budget))} and bid of NPR 0.96</p>
                                                <div className="performance-metrics">
                                                    <div className="metric">
                                                        <h3>Conversions</h3>
                                                        <p>Unavailable</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Impressions</h3>
                                                        <p>{formatNumberWithSuffix(Math.floor(formData.budget / 0.0073))} – {formatNumberWithSuffix(Math.floor(formData.budget / 0.0028))}</p>
                                                    </div>
                                                    <div className="metric">
                                                        <h3>Average CPA</h3>
                                                        <p>Unavailable</p>
                                                    </div>

                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "facebook" && (formData1.campaign_subtype === "Lead Ads" || formData1.campaign_subtype === "Page-likes" || formData1.campaign_subtype === "Image Ads" || formData1.campaign_subtype === "Video-Ads" || formData1.campaign_subtype === "Carousel Ads" ||
                                            formData1.campaign_subtype === "Slideshow Ads"
                                            || formData1.campaign_subtype === "Collection Ads" || formData1.campaign_subtype === "Instant Experience (Canvas) Ads" ||
                                            formData1.campaign_subtype === "Dynamic Ads" || formData1.campaign_subtype === "Messenger Ads"
                                            || formData1.campaign_subtype === "Stories Ads" || formData1.campaign_subtype === "Playable Ads"
                                            || formData1.campaign_subtype === "Poll Ads" || formData1.campaign_subtype === "Event Ads" || formData1.campaign_subtype === "Offer Ads") && formData1.budget !== "" ?
                                            (<div className="campaign-estimates">
                                                <div className="info-box">
                                                    <i className="info-icon">i</i>
                                                    <p>Estimates aren’t currently available for this type of campaigns</p>
                                                </div>
                                            </div>
                                            ) : null

                                    }

                                    {
                                        activeSocialMedia === "instagram" && (formDataI.campaign_subtype === "Lead Ads" || formDataI.campaign_subtype === "Page-likes" || formDataI.campaign_subtype === "Image Ads" || formDataI.campaign_subtype === "Video-Ads" || formDataI.campaign_subtype === "Carousel Ads" ||
                                            formDataI.campaign_subtype === "Slideshow Ads"
                                            || formDataI.campaign_subtype === "Collection Ads" || formDataI.campaign_subtype === "Instant Experience (Canvas) Ads" ||
                                            formDataI.campaign_subtype === "Dynamic Ads" || formDataI.campaign_subtype === "Messenger Ads"
                                            || formDataI.campaign_subtype === "Stories Ads" || formDataI.campaign_subtype === "Playable Ads"
                                            || formDataI.campaign_subtype === "Poll Ads" || formDataI.campaign_subtype === "Event Ads" || formDataI.campaign_subtype === "Offer Ads") && formDataI.budget !== "" ?
                                            (<div className="campaign-estimates">
                                                <div className="info-box">
                                                    <i className="info-icon">i</i>
                                                    <p>Estimates aren’t currently available for this type of campaigns</p>
                                                </div>
                                            </div>
                                            ) : null

                                    }
                                    {
                                        activeSocialMedia === "google" && (formDataG.campaign_subtype === "Sales" || formDataG.campaign_subtype === "Page-likes" || formDataG.campaign_subtype === "Leads" || formDataG.campaign_subtype === "Website traffic" || formDataG.campaign_subtype === "App promotion" ||
                                            formDataG.campaign_subtype === "Local store visits and promotions"
                                        ) && formDataG.budget !== "" ?
                                            (<div className="campaign-estimates">
                                                <div className="info-box">
                                                    <i className="info-icon">i</i>
                                                    <p>Estimates aren’t currently available for this type of campaigns</p>
                                                </div>
                                            </div>
                                            ) : null

                                    }


                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

/*

*/


export default UserHome;