import logo from './logo.svg';
import './App.css';
import Navbar from './Component/Navbar/Navbar';
import UserHome from './Component/Pages/UserHome';
import CustomSelect from './Component/Pages/CustomSelect';
import Login from './Component/Pages/Login';
import Register from './Component/Pages/Register';
import AllRoutes from './Component/AllRoute/AllRoutes';


function App() {
  return (
    <div className="App">
     <AllRoutes/>
    </div>
  );
}

export default App;
