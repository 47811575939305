import { Avatar, Box, Button, Input, Stack, Text, useBreakpointValue, useColorModeValue, useToast } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

function MyProfile() {
    let [user, setUser] = useState({ name: '', email: '', mobile: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [load, setLoad] = useState(false);
    const emailCookie = Cookies.get('email');
    const emailuser = emailCookie ? JSON.parse(emailCookie) : null;
    let toast = useToast()
    const boxWidth = useBreakpointValue({ base: 'full', md: '50%', lg: '50%' });
    const avatarSize = useBreakpointValue({ base: 'xl', md: '2xl' });


    let fetchUserData = async () => {
        try {
            let response = await fetch(`https://releasesnepal.net/register/${emailuser}`);
            let res = await response.json();
            setUser(res.users);
            console.log(res)
            sessionStorage.setItem("email", JSON.stringify(res.users))
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    let handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    let handleEditSaveClick = async () => {
        if (isEditing) {
            setLoad(true);
            try {
                let response = await fetch(`https://releasesnepal.net/register/${user.email}`, {
                    method: "PUT", // Use PUT or PATCH for updating data
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(user)
                });
                if (response.ok) {
                    toast({
                        description: "Profile updated successfully",
                        status: 'success',
                        duration: 9000,
                        position: "top",
                        isClosable: true,
                    })
                    setLoad(false);
                    setIsEditing(false);
                }
            } catch (error) {
                console.log(error);
                setLoad(false);
            }
        } else {
            setIsEditing(true);
        }
    };

    return (
        <>

            <Box>
                <Box>
                    <Navbar />
                </Box>
                <Box >
                    <Box>
                        <Box
                            maxW={boxWidth}
                            w={'full'}
                            bg={"white"}

                            boxShadow={{ base: "", sm: "", md: "", lg: "2xl", xl: "2xl" }}
                            rounded={'lg'}
                            p={{ base: "0px", sm: "0px", md: "0px", lg: "80px", xl: "80px" }}

                            // textAlign={'center'}
                            m={'auto'}
                            mt={10}
                        >
                            <Avatar
                                size={avatarSize}
                                src={'https://bit.ly/broken-link'}
                                alt={'Avatar Alt'}
                                mb={4}
                                pos={'relative'}
                                display={"block"}
                                margin={"auto"}
                            />

                            {
                                isEditing ? <Box p={"10px"}>
                                    <Text>Name</Text>
                                    <Input
                                        name="name"
                                        value={user.name}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                    />
                                </Box> : <Text textAlign={'center'} fontSize={'2xl'} fontWeight={600} mb={4}>
                                    {user.name}
                                </Text>
                            }
                            {
                                isEditing ? <Box p={"10px"} mt={"20px"}>
                                    <Text>Email</Text>
                                    <Input
                                        name="email"
                                        value={user.email}
                                        isReadOnly
                                    />
                                </Box> : <Text textAlign={'center'} fontWeight={600} color={'gray.500'} mb={4}>
                                    Email: {user.email}
                                </Text>
                            }

                            {
                                isEditing ? <Box p={"10px"} mt={"20px"}>
                                    <Text>Mobile</Text>
                                    <Input
                                        name="mobile"
                                        value={user.mobile}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                    />
                                </Box> : <Text textAlign={'center'} fontWeight={600} color={'gray.500'} mb={4}>
                                    Mobile: {user.mobile}
                                </Text>
                            }

                            <Box mt={"20px"}>
                                <Button
                                    mt={"20px"}
                                    color={"white"}
                                    bg={"blue"}
                                    _hover={{ color: "white", background: "blue" }}
                                    onClick={handleEditSaveClick}
                                    isLoading={load}
                                    display={"block"}
                                    margin={"auto"}
                                >
                                    {isEditing ? "Save" : "Edit"}
                                </Button>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default MyProfile;
