import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContextProvider';

const ProtectedRoute = ({ children }) => {
    // const { auth } = useContext(AuthContext);
  const user=JSON.parse(sessionStorage.getItem("email"))||""
    

    return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
