import React from 'react';
import Modal from 'react-modal';
import './My.css';

Modal.setAppElement('#root');

const MetricModal = ({ isOpen, onRequestClose, onMetricSelect, currentMetricName, position }) => {
  const metrics = [
    'Clicks', 'Impressions', 'CTR', 'Avg. CPC', 'Avg. CPM', 'Cost', 'Avg. CPV', 'Views', 'View rate', 'Interactions',"Interaction rate"
  ];

  const customStyles = {
    content: {
      top: `${position.top}px`,
      left: `${position.left}px`,
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(0, 10%)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Select Metric"
      style={customStyles}
      className="metric-modal"
      overlayClassName="metric-modal-overlay"
    >
      {/* <h2>Select Metric for {currentMetricName}</h2> */}
      <ul className="metric-list">
        {metrics.map((metric, index) => (
          <li key={index} onClick={() => onMetricSelect(metric)}>
            {metric}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default MetricModal;
