// src/ThankYouPage.js

import React, { useEffect, useState } from 'react';
import './My.css'; // Import the CSS file for styling
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import tick from "../../images/tick.jpg"


const ThankYouPage = () => {
    let navigate = useNavigate();
    const emailCookie = Cookies.get('email');
    const email = emailCookie ? JSON.parse(emailCookie) : null;
    let referenceId = localStorage.getItem("refrenceId") || "";
    console.log(referenceId);

    const [countdown, setCountdown] = useState(10);

    const fetchSuccess = async () => {
        try {
            let response = await fetch(`https://releasesnepal.net/payment/transaction/success/${referenceId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ status: "Success" })
            });
            let res = await response.json();
            console.log(res);
            if (response.ok) { // Check if the response is OK
                const timerId = setInterval(() => {
                    setCountdown((prevCountdown) => {
                        if (prevCountdown <= 1) {
                            clearInterval(timerId);
                            navigate("/"); // Redirect after countdown reaches zero
                            return 0;
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // const sendEmailOnSuccess = async () => {
    //     try {
    //         const response = await fetch('http://localhost:8000/email/send-email-on-success', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 email, // Example email
    //             }),
    //         });

    //         const result = await response.json();
    //         console.log(result); // Log the result or handle as needed

    //     } catch (error) {
    //         console.error('Error sending email:', error.message);
    //     }
    // };

    useEffect(() => {
        fetchSuccess();
    }, [])

    return (
        <Box className="thank-you-page">
            <Heading as='h1' size='2xl' noOfLines={1}>Thank you !</Heading>
            {/* <h4></h4> */}
            <div className="message-box">
                <p>We'll be in touch with you shortly.</p>
                <p>Our team will contact you soon within 24 hours.</p>
            </div>
            <div style={{ marginTop: "20px" }}>
                <img src={tick} alt="Whale"  style={{ margin: "auto",width:"20%" }} className="whale-image" />
            </div>

            <div>

                <Button color={"white"} bg={"rgb(23, 112, 228)"} _hover={{backgroundColor:"rgb(23, 112, 228)",colorL:"white"}} >Redirecting  in {countdown}</Button>
            </div>

            <div>
                
                <Text mt={"10px"} fontSize='xs'>Please Don't refresh page</Text>
            </div>

        </Box>

    );
};

export default ThankYouPage;
