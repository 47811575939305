// src/ThankYouPage.js

import React, { useEffect } from 'react';
import './My.css'; // Import the CSS file for styling
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const Failure = () => {
    let navigate=useNavigate()
    const email = JSON.parse(Cookies.get('email'));
    let referenceId=localStorage.getItem("refrenceId")||""

    const fetchSuccess=async()=>{
        try {
           let response=await fetch(`https://releasesnepal.net/payment/transaction/success/${referenceId}`,{
            method:"PUT",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({status:"Success"})
           })
           let res=await response.json();
           console.log(res) 
        } catch (error) {
            console.log(error)
        }
    }

    // const sendEmailOnSuccess = async () => {
    //     try {
    //         const response = await fetch('https://relses.onrender.com/email/send-email-on-failure', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 email, // Example email
    //             }),
    //         });

    //         const result = await response.json();
    //         console.log(result); // Log the result or handle as needed

    //     } catch (error) {
    //         console.error('Error sending email:', error.message);
    //     }
    // };

    useEffect(() => {
        fetchSuccess();
    }, [])
    return (
        <div className="thank-you-page">
            <h1>Payment Failed!</h1>
            <div className="message-box">
                <p>Plzz fill the details again.</p>
                {/* <p>.</p> */}
            </div>
            {/* <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRtNW-OXHYg9iVMgbP6TY9GDjyU68Gg640jw&s"} alt="Whale" className="whale-image" /> */}
            <div>
                <Button className='buttonthank' onClick={()=>navigate("/")}>Home</Button>
            </div>

        </div>

    );
};

export default Failure;
