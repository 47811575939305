import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        
    });

    // useEffect(() => {
    //     // Check if the user is authenticated by making a request to the backend
    //     axios.get('https://releasesnepal.net/login/check-auth', { withCredentials: true })
    //         .then(response => {
    //             if (response.data.isAuthenticated) {
    //                 setAuth({ isAuthenticated: true });
    //             } else {
    //                 setAuth({ isAuthenticated: false});
    //             }
    //         })
    //         .catch(error => {
    //             console.error("Error checking authentication", error);
    //             setAuth({ isAuthenticated: false});
    //         });
    // }, []);

    // const login = () => {
    //     setAuth({ isAuthenticated: true });
    // };

   
    console.log(auth)

    return (
        <AuthContext.Provider >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
