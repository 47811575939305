import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react"
import { HamburgerIcon, MinusIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContextProvider";
import axios from "axios";

function Navbar() {
    let navigate = useNavigate()
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();
    // let { setAuth, auth,logout } = useContext(AuthContext)
    const emailCookie = Cookies.get('email');
    const emailuser = emailCookie ? JSON.parse(emailCookie) : null;

    console.log(emailuser)



    const logout = () => {
        console.log("hiiiii")
        sessionStorage.clear();
        navigate("/login")

    };


    return (
        <>
            <Box boxShadow="rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;" p={"5px"}>
                <Box borderRadius={"10px"} p={"3px"}>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                        <Box w={{ base: "30%", sm: "30%", md: "30%", lg: "15%", xl: "15%" }}>
                            <Image pl={"20px"} w={"100%"} border={"10px"} src="https://releasesnepal.com/Releases%20World%20Logo.png" />
                        </Box>
                        <Box pr={"20px"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                            <Flex gap={"20px"} alignItems={"center"}>
                                <Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} onClick={() => navigate("/")} cursor={"pointer"} fontSize={"15px"}>Services</Text>
                                <Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} onClick={() => navigate("/order")} cursor={"pointer"} fontSize={"15px"}>Order</Text>
                                {/* <i className="fa-solid fa-user fa-lg"></i> */}
                                {
                                    emailuser ? <>  <Box>
                                        <Flex gap={"20px"} alignItems={"center"}>
                                            <Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} cursor={"pointer"} onClick={() => navigate("/profile")}> My Profile</Text>
                                            <Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} cursor={"pointer"} onClick={logout}>
                                                Logout
                                            </Text>
                                        </Flex>
                                    </Box></> : <> <Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} onClick={() => navigate("/login")} cursor={"pointer"} fontSize={"15px"}>Login</Text></>

                                }
                                {
                                    emailuser === "" ? <><Text fontWeight={"600"} _hover={{ color: "rgb(24,112,229)" }} onClick={() => navigate("/register")} cursor={"pointer"} fontSize={"15px"}>Register</Text></>
                                        : null
                                }



                            </Flex>
                        </Box>
                        <Box fontSize={{ base: "20px", sm: "20px", md: "50px" }} onClick={openModal2} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}>
                            <HamburgerIcon></HamburgerIcon>
                        </Box>
                    </Flex>
                </Box>
                <Box>
                    <Drawer
                        isOpen={isModal2Open}
                        placement='right'
                        onClose={closeModal2}

                    >
                        <DrawerOverlay />
                        <DrawerContent >
                            <DrawerCloseButton />
                            {/* <DrawerHeader borderBottom={"1px solid #eee"}>Admin Panel</DrawerHeader> */}

                            <DrawerBody mt={"20px"}>
                                <Box>
                                    <Flex alignItems={"center"} gap={"5px"}>
                                        <i class="fa-solid fa-gears fa-sm"></i>
                                        <Text cursor={"pointer"} fontSize={"sm"} onClick={() => navigate("/")} >Services</Text>
                                    </Flex>
                                </Box>
                                <Divider mt={"5px"} />

                                <Box mt={"10px"}>
                                    <Flex alignItems={"center"} gap={"5px"}>
                                        <i class="fa-solid fa-bag-shopping fa-sm"></i>
                                        <Text cursor={"pointer"} fontSize={"sm"} onClick={() => navigate("/order")} >Orders</Text>
                                    </Flex>
                                </Box>
                                <Divider mt={"5px"} />
                                {
                                    emailuser === "" ? <>
                                        <Box mt={"10px"}>
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-regular fa-registered fa-sm"></i>
                                                <Text fontSize={"sm"} cursor={"pointer"} onClick={() => navigate("/register")}>Register</Text>
                                            </Flex>
                                        </Box>
                                        <Divider mt={"5px"} />
                                    </> : <>
                                        <Box mt={"10px"}>
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-user fa-sm"></i>
                                                <Text fontSize={"sm"} cursor={"pointer"} onClick={() => navigate("/profile")}>My Profile</Text>
                                            </Flex>
                                        </Box>
                                        <Divider mt={"5px"} />
                                    </>
                                }
                                {
                                    emailuser === "" ? <>
                                        <Box mt={"10px"}>
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-right-to-bracket fa-sm"></i>
                                                <Text fontSize={"sm"} cursor={"pointer"} onClick={() => navigate("/login")} >Login</Text>
                                            </Flex>
                                        </Box>
                                        <Divider mt={"5px"} />
                                    </> : <>
                                        <Box mt={"10px"}>
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-right-to-bracket fa-sm"></i>
                                                <Text fontSize={"sm"} cursor={"pointer"} onClick={logout} >Logout</Text>
                                            </Flex>
                                        </Box>
                                        <Divider mt={"5px"} />
                                    </>
                                }







                                {/* <Box mt={"10px"}>
                                        <Flex alignItems={"center"} gap={"5px"}>
                                        <i class="fa-brands fa-first-order-alt fa-sm"></i>
                                        <Text mt={"5px"} fontSize={"sm"} cursor={"pointer"}  >Order history</Text>
                                        </Flex>
                                    </Box>
                                    <Divider mt={"5px"}/> */}

                            </DrawerBody>

                            <DrawerFooter>

                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Box>
            </Box>
        </>
    )
}
export default Navbar;
