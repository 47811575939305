import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Spinner, Text } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImg from "../../images/login.jpg"
import { AuthContext } from '../Context/AuthContextProvider';
import axios from 'axios';
import Cookies from 'js-cookie';




function Login() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let [next, setNext] = useState(true);
    let navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    // const { login } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNext(false)
        try {
            const response = await axios.post('https://releasesnepal.net/login', {
                email,
                password
            }, { withCredentials: true });

            if (response.data.isError) {
                setError(response.data.message);
            } else {
                // console.log(response.data.users)

                
                Cookies.set("email", JSON.stringify(response.data.users.email))
                sessionStorage.setItem("email", JSON.stringify(response.data.users))
                setError('');
                // alert('Login successful!');
                setNext(true)
                
                navigate("/")
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
            setNext(true)
        }
    };
    return (
        <>
            <Box>
                <Box>
                    <Flex>
                        <Box w={"50%"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                            <Image h={"100vh"} w={"100%"} src={loginImg} />
                        </Box>
                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} p={{ base: "20px", sm: "20px", md: "20px", lg: "10rem", xl: "10rem" }}>
                            <form onSubmit={handleSubmit}>
                                <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">LOGIN</Text>
                                <Box mt={"30px"} >
                                    <Text float={"left"} fontWeight={"500"}>Email</Text>
                                    <Input

                                        name="email"
                                        placeholder="Enter Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        autoComplete="email"  // Add this line
                                    />
                                </Box>
                                <Box mt={"30px"}>
                                    <Text float={"left"} fontWeight={"500"}>Password</Text>
                                    <InputGroup size="md">
                                        <Input
                                            name="password"

                                            pr="4.5rem"
                                            type={show ? "text" : "password"}
                                            fontSize="14px"
                                            _placeholder={{ fontSize: "14px" }}
                                            placeholder="Enter Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required

                                        />
                                        <InputRightElement width="4.5rem" >
                                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                                                {show ? "Hide" : "Show"}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </Box>
                                <Box>
                                    <Flex justifyContent={{ base: "center", lg: "space-between" }} alignItems="center" flexDirection={{ base: "column", lg: "row" }}>
                                        {
                                            next ? <Button
                                                mt="20px"
                                                color="white"
                                                bg="rgb(24,112,229)"
                                                size="md"
                                                type="submit"
                                            >
                                                LOGIN
                                            </Button> : <Button
                                                mt="20px"
                                                color="white"
                                                bg="rgb(24,112,229)"
                                                size="md"
                                                type="submit"
                                            >
                                                <Spinner />
                                            </Button>
                                        }
                                        <Text mt={{ base: "20px", lg: "0" }} _hover={{ color: "rgb(24,112,229)" }}
                                            //  onClick={() => navigate("/forget")} 
                                            cursor={"pointer"}>
                                            Forget Password ?
                                        </Text>
                                    </Flex>
                                </Box>
                                <Box mt={"20px"}>
                                    <Flex>
                                        <Text mt={{ base: "20px", lg: "0" }} _hover={{ color: "rgb(24,112,229)" }}
                                            //  onClick={() => navigate("/forget")} 
                                            cursor={"pointer"}>
                                            Don't have an account ?
                                        </Text>
                                        <Text mt={{ base: "20px", lg: "0" }} _hover={{ color: "rgb(24,112,229)" }}
                                            //  onClick={() => navigate("/forget")} 
                                            cursor={"pointer"} color="rgb(24,112,229)" onClick={() => navigate("/register")} textDecoration={"underline"}>
                                            Register
                                        </Text>
                                    </Flex>
                                </Box>
                            </form>

                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

export default Login;
