import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Pages/Login';
import Register from '../Pages/Register';
import UserHome from '../Pages/UserHome';
import ProtectedRoute from './PrivateRoute';
import { AuthProvider } from '../Context/AuthContextProvider';
import Order from '../Pages/Order';
import ThankYouPage from '../Pages/ThankyouPage';
import Failure from "../Pages/Failure"
import MyProfile from '../Pages/MyProfile';

const AllRoutes = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                {/* <Route path="/order" element={ <ProtectedRoute><Order /></ProtectedRoute>} /> */}
                <Route path="/order" element={ <Order />}/>
                {/* <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <UserHome />
                        </ProtectedRoute>
                    }
                />
                 <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <MyProfile />
                        </ProtectedRoute>
                    }
                /> */}
                {/* <Route
                    path="/"
                    element={
                        
                            <UserHome />
                       
                    }
                /> */}
                 <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <UserHome />
                            </ProtectedRoute>
                    }
                />
                <Route path="/success" element={<ProtectedRoute><ThankYouPage /></ProtectedRoute>} />
                <Route path="/failure" element={<ProtectedRoute><Failure /></ProtectedRoute>} />
            </Routes>
        </AuthProvider>
    );
};

export default AllRoutes;
