import { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./My.css";
import { FaPlus, FaBullhorn } from 'react-icons/fa';
import MetricModal from "./MetricModal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from "../Navbar/Navbar";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import Cookies from 'js-cookie';

function Order() {
    const initialMetrics = [
        { name: 'Clicks', value: 0, color: 'blue' },
        { name: 'Impressions', value: 0, color: 'red' },
        { name: 'Avg. CPC', value: '₹0.00', color: 'orange' },
        { name: 'Cost', value: '₹0.00', color: 'green' },
    ];

    const [selectedMetrics, setSelectedMetrics] = useState(initialMetrics);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activeMetricIndex, setActiveMetricIndex] = useState(null);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const metricRefs = useRef([]);
    const navigate = useNavigate();
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedAcNumber, setSelectedAcNumber] = useState('');
    const [uniqueAcNumbers, setUniqueAcNumbers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const emailCookie = Cookies.get('email');
    const emailFind = JSON.parse(sessionStorage.getItem("email")) || ""
  
    const {email}=emailFind
    console.log(email)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await axios.get(`https://releasesnepal.net/report/${email}`);
                const data = response.data.user;

                if (Array.isArray(data)) {
                    const acNumbers = [...new Set(data.map(item => item.AcNumber))];
                    setUniqueAcNumbers(acNumbers);
                    setSelectedAcNumber(acNumbers[0]);
                    setReportData(data);
                } else {
                    setError("Data is not an array.");
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, []);

    useEffect(() => {
        if (reportData.length > 0) {
            updateMetrics();
        }
    }, [selectedAcNumber, startDate, endDate, reportData]);

    const openModal = (index) => {
        setActiveMetricIndex(index);
        const metricBox = metricRefs.current[index].getBoundingClientRect();
        setModalPosition({ top: metricBox.top, left: metricBox.left });
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setActiveMetricIndex(null);
    };

    const getMetricValue = (metricName, filteredData) => {
        const clicks = filteredData.reduce((sum, item) => sum + item.Clicks, 0);
        const cost = filteredData.reduce((sum, item) => sum + item.Cost, 0);
        const impressions = filteredData.reduce((sum, item) => sum + item.Impressions, 0);
        const views = filteredData.reduce((sum, item) => sum + item.Views, 0);
        const interactions = filteredData.reduce((sum, item) => sum + item.Interactions, 0);
        const CTR = filteredData.reduce((sum, item) => sum + item.CTR, 0);
        const Interactionrate = filteredData.reduce((sum, item) => sum + item.Interactionrate, 0);
        const ViewRate = filteredData.reduce((sum, item) => sum + item.ViewRate, 0);
        const AvgCPC = filteredData.reduce((sum, item) => sum + item.AvgCPC, 0);
        const AvgCPM = filteredData.reduce((sum, item) => sum + item.AvgCPM, 0);
        const AvgCPV = filteredData.reduce((sum, item) => sum + item.AvgCPV, 0);
        switch (metricName) {
            case 'Clicks':
                return clicks;
            case 'Impressions':
                return impressions;
            case 'CTR':
                return `${CTR.toFixed(2)}%`;
            case 'Avg. CPC':
                return `₹${(AvgCPC).toFixed(2)}`;
            case 'Cost':
                return `₹${cost.toFixed(2)}`;
            case 'Avg. CPM':
                return `₹${((AvgCPM)).toFixed(2)}`;
            case 'Avg. CPV':
                return `₹${(AvgCPV).toFixed(2)}`;
            case 'Views':
                return views;
            case 'View rate':
                return `${ViewRate.toFixed(2)}%`;
            case 'Interactions':
                return interactions;
            case 'Interaction rate':
                return `${Interactionrate.toFixed(2)}%`;
            default:
                return 0;
        }
    };

    const updateMetrics = () => {
        if (!startDate || !endDate) {
            return;
        }

        const filteredData = reportData.filter(item => {
            const createdAtDate = new Date(item.createdAt);
            const createdAtStartOfDay = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), createdAtDate.getDate());

            const startDateStartOfDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const endDateStartOfDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

            return (
                item.AcNumber === selectedAcNumber &&
                createdAtStartOfDay >= startDateStartOfDay &&
                createdAtStartOfDay <= endDateStartOfDay
            );
        });

        setSelectedMetrics([
            { name: 'Clicks', value: getMetricValue('Clicks', filteredData), color: 'blue' },
            { name: 'Impressions', value: getMetricValue('Impressions', filteredData), color: 'red' },
            { name: 'Avg. CPC', value: getMetricValue('Avg. CPC', filteredData), color: 'orange' },
            { name: 'Cost', value: getMetricValue('Cost', filteredData), color: 'green' },
        ]);
    };

    const handleMetricSelect = (metricName) => {
        const updatedMetrics = [...selectedMetrics];

        const filteredData = reportData.filter(item => {
            const createdAtDate = new Date(item.createdAt);
            const createdAtStartOfDay = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), createdAtDate.getDate());

            const startDateStartOfDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const endDateStartOfDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

            return (
                item.AcNumber === selectedAcNumber &&
                createdAtStartOfDay >= startDateStartOfDay &&
                createdAtStartOfDay <= endDateStartOfDay
            );
        });

        const newValue = getMetricValue(metricName, filteredData);
        updatedMetrics[activeMetricIndex] = {
            ...updatedMetrics[activeMetricIndex],
            name: metricName,
            value: newValue
        };

        setSelectedMetrics(updatedMetrics);
        closeModal();
    };

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start ? new Date(start.getFullYear(), start.getMonth(), start.getDate()) : null);
        setEndDate(end ? new Date(end.getFullYear(), end.getMonth(), end.getDate()) : null);
    };



    const handleAcNumberChange = (event) => {
        setSelectedAcNumber(event.target.value);
    };

    const navItems = [
        { icon: <FaPlus />, label: 'Create' },
        { icon: <FaBullhorn />, label: 'Campaigns', active: true },
    ];
    console.log(startDate)
    console.log(endDate)

    let [ads, setAds] = useState([])

    let fetchUserAds = async () => {
        try {
            let [googleAdsResponse, youtubeAdsResponse, instagramAdsResponse, facebookAdsResponse] = await Promise.all([
                fetch(`https://releasesnepal.net/${email}`).then(response => response.json()),
                fetch(`https://releasesnepal.net/${email}`).then(response => response.json()),
                fetch(`https://releasesnepal.net/${email}`).then(response => response.json()),
                fetch(`https://releasesnepal.net/${email}`).then(response => response.json())
            ]);


            // Ensure all responses are arrays
            let googleAds = Array.isArray(googleAdsResponse.user) ? googleAdsResponse.user : [];
            let youtubeAds = Array.isArray(youtubeAdsResponse.user) ? youtubeAdsResponse.user : [];
            let instagramAds = Array.isArray(instagramAdsResponse.user) ? instagramAdsResponse.user : [];
            let facebookAds = Array.isArray(facebookAdsResponse.user) ? facebookAdsResponse.user : [];

            // Combine all ads into a single array
            let allAds = [...googleAds, ...youtubeAds, ...instagramAds, ...facebookAds];
            console.log(allAds);
            // setAds(allAds)
            return allAds;
        } catch (error) {
            console.log(error);
        }
    };

    let params = "admin181201"

    const fetchPaymentDetail = async () => {
        try {
            let response = await fetch(`https://releasesnepal.net/payment/transactions/${params}`);
            let paymentDetail = await response.json();
            console.log("Payment Details:", paymentDetail);
            //   return paymentDetail;
            return paymentDetail;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const mergeData = async () => {
        const allAds = await fetchUserAds();
        const paymentDetails = await fetchPaymentDetail();

        if (!allAds || !paymentDetails) {
            return;
        }

        const merged = allAds.map(ad => {
            const matchingPayment = paymentDetails.find(payment => payment.referenceId === ad.AcNumber);
            if (matchingPayment) {
                // Ensure all relevant fields are merged
                return {
                    ...ad,
                    ...matchingPayment,
                    // Add default values for any missing fields

                    // Add any other fields as required
                };
            }
            return ad;
        });

        console.log(merged);
        setAds(merged);
    };

    useEffect(() => {
        mergeData();
    }, []);

    const userPerPage = 5;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = ads.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(ads.length / userPerPage);

    return (
        <>
            <Box position={"relative"}>
                <Box zIndex={1111} position={"fixed"} bg={"white"}><Navbar /></Box>
                <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                    <div className="navbar">
                        {navItems.map((item, index) => (
                            <div key={index} className={`nav-item ${item.active ? 'active' : ''}`}>
                                <div className="icon">{item.icon}</div>
                                <div className="label">{item.label}</div>
                            </div>
                        ))}
                    </div>
                </Box>
                <div className="content">
                    <div className="overview-header" style={{ marginTop: "40px" }}>
                        <button className="new-campaign-button" onClick={() => navigate('/')}><i className="fa-solid fa-plus"></i> Create Ad</button>
                        <div className="date-range">
                            <div className="daterelease">
                                <h2 className="h1psb">Select Date: </h2>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    className="custom-datepicker"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="overview-header">
                        <div>
                            <h2 className="h2withreport">Ads Report</h2>
                        </div>
                        <div >
                            {
                                uniqueAcNumbers.length > 0 ? <select value={selectedAcNumber} className="selectaccrodingtoID" onChange={handleAcNumberChange}>
                                    {uniqueAcNumbers.map((acNumber, index) => (
                                        <option key={index} value={acNumber}>{acNumber}</option>
                                    ))}
                                </select> : null
                            }
                        </div>
                    </div>
                    <div className="metrics-container">
                        {selectedMetrics.map((metricO, index) => (
                            <div
                                key={index}
                                className={`metricO ${metricO.color}`}
                                onClick={() => openModal(index)}
                                ref={(el) => (metricRefs.current[index] = el)}
                            >
                                <span className="metric-name">{metricO.name}<i style={{ marginLeft: "8px" }} className="fa-solid fa-caret-down"></i></span>
                                <span className="metric-value">{metricO.value}</span>
                            </div>
                        ))}
                        {modalIsOpen && (
                            <MetricModal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                onMetricSelect={handleMetricSelect}
                                currentMetricName={selectedMetrics[activeMetricIndex]?.name}
                                position={modalPosition}
                            />
                        )}
                    </div>

                    

                        <Box mb="20px">
                            <div>
                                <h2 className="h2withreport">All Campaign</h2>
                            </div>
                            <Divider display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} mb="40px" borderColor={"#ccc"} />
                            <TableContainer
                                m="auto"
                                borderRadius="5px"
                                w={{ base: "100%", md: "90%" }}
                                // border="1px solid #ccc"
                                // p={{ base: "10px", md: "20px" }}
                                // className="table-container"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
                            >
                                <Table
                                    size="lg"
                                    // variant="striped"
                                    // colorScheme="teal"
                                    className="table"
                                >
                                    <Thead bg={"rgb(23, 112, 228)"} p={"20px"}>
                                        <Tr p={"40px"}>
                                            <Th textAlign={"center"} color={"white"}>Order Id</Th>
                                            <Th textAlign={"center"} color={"white"}>Ad platform</Th>
                                            <Th textAlign={"center"} color={"white"}>Campaign Subtype</Th>
                                            <Th textAlign={"center"} color={"white"}>Budget</Th>
                                            <Th textAlign={"center"} color={"white"}>Status</Th>
                                            <Th textAlign={"center"} color={"white"}>Date</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {user.length > 0 ? (
                                            user.map((ele, idx) => (
                                                <Tr key={idx} >
                                                    <Td textAlign={"center"}>{ele.AcNumber}</Td>
                                                    <Td textAlign={"center"}> {ele.ad_platform}</Td>
                                                    <Td textAlign={"center"}>{ele.campaign_subtype}</Td>
                                                    <Td textAlign={"center"}>{ele.budget}</Td>
                                                    <Td textAlign={"center"}>{ele.status}</Td>
                                                    <Td textAlign={"center"}>{new Date(ele.createdAt).toLocaleString('en-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true
                                                    })}</Td>
                                                </Tr>
                                            ))
                                        ) : (
                                            <Tr>
                                                <Td textAlign={"center"} colSpan="6">No data available</Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </Table>
                                {user.length > 0 && (
                                    <Flex p={"10px"} alignItems="center" justifyContent="right" gap="10px" mt="10px" className="pagination">
                                        <Text fontWeight="bold">
                                            {currentPage} of {totalPages}
                                        </Text>
                                        <Button
                                            bg="rgb(23, 112, 228)"
                                            color="white"
                                            _hover={{ cursor: "pointer", bg: "rgb(23, 112, 228)" }}
                                            isDisabled={currentPage === 1}
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                        >
                                            <i class="fa-solid fa-chevron-left"></i>
                                        </Button>
                                        <Button
                                            bg="rgb(23, 112, 228)"
                                            color="white"
                                            _hover={{ cursor: "pointer", bg: "rgb(23, 112, 228)" }}
                                            isDisabled={currentPage === totalPages}
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                        >
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </Button>
                                    </Flex>
                                )}
                            </TableContainer>
                        </Box>
                   
                </div>
            </Box>
        </>
    );
}

export default Order;
