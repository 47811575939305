import { Box, Button, Center, Flex, FormControl, HStack, Heading, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, PinInput, PinInputField, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImg from "../../images/login.jpg"
import Cookies from 'js-cookie';
import { AuthContext } from '../Context/AuthContextProvider';



function Register() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let [next, setNext] = useState(true);
    let toast = useToast()
    let navigate = useNavigate()
    const [data, setData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: ""
    })
    let reemail = JSON.parse(sessionStorage.getItem("reemail"))
    const [confirmpass, setConfirmPass] = useState()
    let [load, setLoad] = useState(false)
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    const [otp, setOpt] = useState("");
    const [showResendButton, setShowResendButton] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    // const { login } = useContext(AuthContext);

  


    console.log(resendTimer)
    let storeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handlePinChange = (value) => {
        // Implement logic to handle the OTP input change
        setOpt(value);
        // You can store the entered OTP in state for further processing
    };
    console.log(reemail)
    let handleSubmit = async (e) => {
        e.preventDefault();
        setNext(false);
        if (data.password === confirmpass) {
            try {
                let response = await fetch(`https://releasesnepal.net/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                if (response.ok) {
                    // toast({
                    //     description: "Otp sent Successfully",
                    //     status: 'sucess',
                    //     duration: 5000,
                    //     isClosable: true,
                    //     position: "top"
                    // })
                    let res = await response.json();
                    sessionStorage.setItem("reemail", JSON.stringify(res.email))
                    openModal1();
                    setNext(true)
                   
                    // login();
                    
                   
                    setResendTimer(30)
                    setShowResendButton(false);
                    


                }
            } catch (error) {
                console.log(error)
                toast({
                    description: "Something went wrong",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                })
                setNext(true)
            }
        } else {
            toast({
                description: "Password and  confirm password should be same",
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
            setNext(true)
        }
    }

    let verifyOtp = async () => {
        setLoad(true);
        try {
            let response = await fetch(`https://releasesnepal.net/register/verify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "email":reemail, otp })
            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                Cookies.set("email",JSON.stringify(res.user.email))
                
                sessionStorage.setItem("email", JSON.stringify(res.user))
                // console.log(res);
                toast({
                    description: `${res.message}`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                })
                // sessionStorage.clear();
                setLoad(false);
                navigate("/")

            }
        } catch (error) {
            toast({
                description: "Something went wrong",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top"
            })
            setLoad(false);
        }
    }

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        } else {
            setShowResendButton(true);
        }

        return () => clearTimeout(timer);
    }, [resendTimer]);

    const handleResendOTP = async () => {
        // Logic to resend OTP
        // For now, let's reset the timer to 30 seconds
        try {
            let response = await fetch(`https://releasesnepal.net/register/resend`, {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ "email":reemail })
            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                toast({
                    description: "OTP resent successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                })
                setResendTimer(30)
                setShowResendButton(false);
            }
        } catch (error) {
            console.log(error)
        }
        // setResendTimer(30);
        // setShowResendButton(false); // Hide the button again
    };
    return (
        <>
            <Modal
                isCentered
                isOpen={isModal1Open}
                onClose={closeModal1}

                size={{ base: "full", sm: "full", md: "full", lg: "sm", xl: "sm" }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
            // motionPreset="scale"
            >
                <ModalOverlay />
                <ModalContent w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "30%" }}>
                    <ModalHeader><Center>
                        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                            Verify your Email
                        </Heading>
                    </Center></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center
                            fontSize={{ base: 'sm', sm: 'md' }}
                            color={'gray.800'}>
                            We have sent code to your Email
                        </Center>
                        <Center
                            fontSize={{ base: 'sm', sm: 'md' }}
                            fontWeight="bold"
                            mt="10px"
                            mb={"20px"}
                            color={'gray.400'}>
                            {data.email}
                        </Center>

                        <FormControl mb={"20px"}>
                            <Center>
                                <HStack>

                                    <PinInput mt="20px"
                                        onChange={handlePinChange}
                                    >
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                            </Center>
                        </FormControl>
                        {
                            load ? <Button bg='rgb(156,156,156)' display={"block"} m={"auto"} color="white"

                            >
                                <Spinner />
                            </Button> : <Button bg='rgb(156,156,156)' display={"block"} m={"auto"} color="white"
                                onClick={verifyOtp}
                            >
                                Verify
                            </Button>
                        }

                        {
                            showResendButton ? (
                                <Box textAlign={"center"} >
                                    <Button mt={"20px"} size={"xs"} onClick={handleResendOTP}>Resend OTP</Button>
                                </Box>
                            ) : (
                                <Box textAlign={"center"} >
                                    <Text mt={"20px"} size={"xs"}>Resend OTP in {resendTimer} seconds</Text>
                                </Box>
                            )
                        }
                        {/* <Button mt={"20px"} size={"xs"} onClick={() => sendOtp(true)}>Resend OTP</Button> */}


                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box>
                <Box>
                    <Flex>
                        <Box w={"50%"} display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>
                            <Image h={"100vh"} w={"100%"} src={"https://img.freepik.com/free-photo/welcome-phrase-available-launch-open_53876-124476.jpg?size=626&ext=jpg&ga=GA1.1.1518270500.1717372800&semt=sph"} />
                        </Box>
                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}} p={{base:"20px",sm:"20px",md:"20px",lg:"3rem",xl:"3rem"}} >
                            <form onSubmit={handleSubmit}>
                                <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">REGISTER</Text>
                                <Box mt={"30px"} >
                                    <Text float={"left"} fontWeight={"500"}>Email</Text>
                                    <Input

                                        name="email"
                                        placeholder="Enter Email Address"
                                        value={data.email}
                                        onChange={storeData}
                                        isRequired
                                        autoComplete="email"  // Add this line
                                    />
                                </Box>
                                <Box mt={"30px"} >
                                    <Text float={"left"} fontWeight={"500"}>Name</Text>
                                    <Input

                                        name="name"
                                        placeholder="Enter Your Name"
                                        value={data.name}
                                        onChange={storeData}
                                        isRequired
                                        autoComplete="email"  // Add this line
                                    />
                                </Box>
                                <Box mt={"30px"} >
                                    <Text float={"left"} fontWeight={"500"}>Mobile</Text>
                                    <Input
                                        type='text'
                                        name="mobile"
                                        placeholder="Enter Mobile"
                                        value={data.mobile}
                                        onChange={storeData}
                                        isRequired
                                        autoComplete="mobile"  // Add this line
                                    />
                                </Box>
                                <Box mt={"30px"}>
                                    <Text float={"left"} fontWeight={"500"}>Password</Text>
                                    <InputGroup size="md">
                                        <Input
                                            name="password"

                                            pr="4.5rem"
                                            type={show ? "text" : "password"}
                                            fontSize="14px"
                                            _placeholder={{ fontSize: "14px" }}
                                            placeholder="Enter Password"
                                            value={data.password}
                                            onChange={storeData}
                                            // onKeyDown={(e) => login(e)}
                                            isRequired
                                        />
                                        <InputRightElement width="4.5rem" >
                                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                                                {show ? "Hide" : "Show"}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </Box>
                                <Box mt={"30px"} >
                                    <Text float={"left"} fontWeight={"500"}>Confirm Password</Text>
                                    <Input

                                        name="confirmpass"
                                        // placeholder="Enter Email Address"
                                        value={confirmpass}
                                        onChange={(e) => setConfirmPass(e.target.value)}
                                        isRequired

                                    />
                                </Box>
                                <Box>
                                    <Flex justifyContent={{ base: "center", lg: "space-between" }} alignItems="center" flexDirection={{ base: "column", lg: "row" }}>
                                        {
                                            next ? <Button
                                                mt="20px"
                                                color="white"
                                                bg="rgb(24,112,229)"
                                                size="md"
                                                type="submit"
                                            >
                                                REGISTER
                                            </Button> : <Button
                                                mt="20px"
                                                color="white"
                                                bg="rgb(24,112,229)"
                                                size="md"
                                                type="submit"
                                            >
                                                <Spinner />
                                            </Button>
                                        }
                                        <Box >
                                            <Flex>
                                                <Text mt={{ base: "20px", lg: "0" }}
                                                //  onClick={() => navigate("/forget")} 
                                                >
                                                    Already have an account ?
                                                </Text>
                                                <Text mt={{ base: "20px", lg: "0" }} _hover={{ color: "rgb(24,112,229)" }}
                                                    //  onClick={() => navigate("/forget")} 
                                                    cursor={"pointer"} onClick={() => navigate("/login")} color="rgb(24,112,229)" textDecoration={"underline"}>
                                                    Login
                                                </Text>
                                            </Flex>
                                        </Box>
                                        {/* <Text mt={{ base: "20px", lg: "0" }}  _hover={{ color: "rgb(24,112,229)" }}
                                        //  onClick={() => navigate("/forget")} 
                                         cursor={"pointer"}>
                                            Forget Password ?
                                        </Text> */}
                                    </Flex>
                                </Box>

                            </form>

                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

export default Register;
